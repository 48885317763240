import './features.css';
import Loading from '../ContentLoading';
const img = 'https://cosmatherapy.com/wp-content/uploads/2020/09/brain2.png';

export default function Brain (props) {
    const data = props.dataFeaturs
    if(!data){return <Loading/>}
    const {benefits_of_cosma} = data[0];
    const {
        benefits_title_one,
        benefits_percent_one,
        benefits_description_one,
        benefits_title_two,
        benefits_percent_two,
        benefits_description_two,
        benefits_title_three,
        benefits_percent_three,
        benefits_description_three,
        benefits_title_four,
        benefits_percent_four,
        benefits_description_four,
        learn_more_link

    } = benefits_of_cosma;

    return (
        <div className='brainWrap' id='brain'>
            <div className='container'>
                <h1 className='brainTitle'>Benefits of COSMA</h1>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='leftContent'>
                            <div className='showOne'>
                                <div className="accordion" id="accordionExample">

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button
                                                className="accordion-button titOne"
                                                type="button"
                                                data-mdb-toggle="collapse"
                                                data-mdb-target="#collapseOne"
                                                aria-expanded="true"
                                                aria-controls="collapseOne"
                                            >
                                               {benefits_title_one} <span id='onePercent'>{benefits_percent_one}%</span>
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseOne"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="headingOne"
                                            data-mdb-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                {benefits_description_one}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button
                                                className="accordion-button titTwo"
                                                type="button"
                                                data-mdb-toggle="collapse"
                                                data-mdb-target="#collapseTwo"
                                                aria-expanded="true"
                                                aria-controls="collapseTwo"
                                            >
                                               {benefits_title_two} <span id='onePercent'>{benefits_percent_two}%</span>
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseTwo"
                                            className="accordion-collapse collapse show"
                                            aria-labelledby="headingTwo"
                                            data-mdb-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                {benefits_description_two} 
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='middleContent'>
                            <img src={img} alt="ss" />
                        </div>
                        <div className='text-center mt-3'>
                        <a href={learn_more_link} className='BtnDemo mb-4'>Learn More</a>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='rightContent'>
                            <div className="accordion" id="accordionExample">

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button titThree"
                                            type="button"
                                            data-mdb-toggle="collapse"
                                            data-mdb-target="#collapseThree"
                                            aria-expanded="true"
                                            aria-controls="collapseThree"
                                        >
                                            {benefits_title_three} <span id='onePercent'>{benefits_percent_three}%</span>
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingThree"
                                        data-mdb-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            {benefits_description_three}
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button titFour"
                                            type="button"
                                            data-mdb-toggle="collapse"
                                            data-mdb-target="#collapseFour"
                                            aria-expanded="true"
                                            aria-controls="collapseFour"
                                        >
                                            {benefits_title_four}<span id='onePercent'>{benefits_percent_four}%</span>
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingFour"
                                        data-mdb-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            {benefits_description_four}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}