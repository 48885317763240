import { IoLogoFacebook, IoLogoTwitter, IoLogoLinkedin, IoLogoYoutube } from "react-icons/io5";
import { Link } from 'react-router-dom';
import axios from 'axios';
import React from 'react';
import './footer.css';




export default function Footer() {
    const [data, setData] = React.useState(null);

    const APIData = "https://cosmatherapy.co.uk/wp-json/wp/v2/footer";

    React.useEffect(() => {
        
        axios.get(APIData).then((res) => {
            setData(res.data);
        });
    }, []);
    if(!data){return "loading"}

    const {facebook, twitter, linkedin, youtube, applestore, playstore} = data[0].acf.follow_us_links;
    const {about_us, blog, company, contact_us, press_relese} = data[0].acf.who_we_are_links;
    const {create_profile, pricing, terms_of_service} = data[0].acf.information;
    const {brainberry_news, subscribe_to_cosma} = data[0].acf.subscribe_links;
    const {terms_of_services, privacy_policy, site_map} = data[0].acf.bottom_footer;

    
    return (
        <div className='footerWrap'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
                        <div className='footerItem'>
                            <h1>Follow Us</h1>
                            <ul>
                                <li>
                                    <ul className='socialList d-flex justify-content-start align-items-center'>
                                        <li><a target="_blank" rel="noreferrer" href={facebook}>
                                            <IoLogoFacebook />
                                        </a></li>
                                        <li><a target="_blank" rel="noreferrer" href={twitter}>
                                            <IoLogoTwitter />
                                        </a></li>
                                        <li><a target="_blank" rel="noreferrer" href={linkedin}>
                                            <IoLogoLinkedin />
                                        </a></li>
                                        <li><a target="_blank" rel="noreferrer" href={youtube}>
                                            <IoLogoYoutube />
                                        </a></li>
                                    </ul>
                                    <p className="mt-4 down">Download Cosma Now</p>
                                    <div className="d-flex mt-3">
                                        <a href={playstore}><img className="footerApp" src='/Images/play.png' alt="ddd"/></a>
                                        <a className="ms-3" href={applestore}> <img className="footerApp" src='/Images/apple.png' alt="ddd"/></a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-6 col-12'>
                        <div className='footerItem'>
                            <h1>Who we are</h1>
                            <ul>
                                <li><a href={company}>Company</a></li>
                                <li><a href={about_us}>About us</a></li>
                                <li><Link to={blog}>Blog</Link></li>
                                <li><a href={press_relese}>Press Release</a></li>
                                <li><a href={contact_us}>Contact us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6 col-12 ps-4'>
                        <div className='footerItem'>
                            <h1>Services</h1>
                            <ul>
                                <li><Link to="/healthcare-professionals">Healthcare Professionals</Link></li>
                                <li><Link to="/healthcare-organisations">Healthcare Organisations</Link></li>
                                <li><Link to="/research-organisations">Research Organisations</Link></li>
                                <li><Link to="/dementia-communities">Dementia Community</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-4 col-sm-6 col-12'>
                        <div className='footerItem'>
                            <h1>Subscribe</h1>
                            <ul>
                                <li><a href={brainberry_news}> BrainBerry News</a></li>
                                <li><a href={subscribe_to_cosma}>Subscribe to COSMA</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-4 col-sm-6 col-12'>
                        <div className='footerItem'>
                            <h1>Information</h1>
                            <ul>
                                <li><Link to={create_profile}>Create Profile</Link></li>
                                <li><Link to={terms_of_service}>Terms of Service</Link></li>
                                <li><Link to={pricing}>Pricing</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='copyRight'>
                    <ul className='text-center d-flex justify-content-center align-items-stretch'>
                        <li><a href={terms_of_services}>Terms of services</a></li>
                        <li><a href={privacy_policy}>Privacy Policy</a></li>
                        <li><a href={site_map}>Site map</a></li>
                        <li><p>© 2021 BrainBerry Ltd. All Rights Reserved. </p></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}