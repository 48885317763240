import React from 'react'
import { Route, Navigate } from 'react-router-dom'
// import { useSelector } from 'react-redux'

function ProtectedRoute({  component: Component, ...rest }) {
    // const user = useSelector(state => state.auth.user)

    const token = localStorage.getItem("access_token")
    return token
        ? (<Route {...rest} component={Component} />)
        : (<Navigate to={'/login'} />)
}

export default ProtectedRoute