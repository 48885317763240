import { createStore, compose, combineReducers,applyMiddleware } from 'redux';
import rootReducer from './reducers'
import organizationReducer from './reducers/OrgReducers/organizationReducers';
import doctorsReducer from './reducers/OrgReducers/doctorsReducers';
import patientsReducer from './reducers/OrgReducers/patientsReducers';
import orgUserReducer from './reducers/OrgReducers/orgUserReducers';
import familyReducers from './reducers/UploadedUI/familyReducers';
import eventsReducers from './reducers/UploadedUI/eventsReducers';
import itemsReducers from './reducers/UploadedUI/itemsReducers';
import ScheduleReducer from './reducers/UploadedUI/ScheduleReducer';
import BehaviouralReducers from './reducers/UploadedUI/BehaviouralReducers';
import MedicationReminderReducer from './reducers/UploadedUI/MedicationReminderReducer';
import AccountReducer from './reducers/AccountReducer';
import SongsReducers from './reducers/UploadedUI/SongsReducers';
import patientDashoardReducers from './reducers/Dashboard/patientDashboardReducers';
const thunkMiddleware =require('redux-thunk').default;


//  const MainStore =combineReducers({
//     user:rootReducer,
//     organizationReducer,
//  });

// const store = createStore(MainStore,{},
//     compose(
//         applyMiddleware(thunkMiddleware),
//         window.devToolsExtension ? window.devToolsExtension() : f => f)
// );


// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const initialState = {}


const mainReducer=combineReducers(
    {
        auth:rootReducer,
        acc:AccountReducer,
        Org:organizationReducer,
        doc:doctorsReducer,
        pat:patientsReducer,
        orgUser:orgUserReducer,
        fam:familyReducers,
        item:itemsReducers,
        event:eventsReducers,
        sch:ScheduleReducer,
        beh:BehaviouralReducers,
        medRem:MedicationReminderReducer,
        song:SongsReducers,
        dash:patientDashoardReducers,
    }
);

const store=createStore(mainReducer,
    compose(
        applyMiddleware(thunkMiddleware),
        window.devToolsExtension ? window.devToolsExtension() : f => f)
    );


export default store;