import { Helmet } from "react-helmet";
// import Frame from "Components/Frame";
import React, { useState } from "react";

import './checkout.css';
import product from '../../Components/data';


export default function Checkout() {
    const [count, setCount] = useState(1);

    // cart editable
    // Create handleIncrement event handler
    const handleIncrement = () => {
        setCount(prevCount => prevCount + 1);
    };

    //Create handleDecrement event handler
    const handleDecrement = () => {
        setCount(prevCount => prevCount - 1);
    };


    // cart editable end
    //   TOTAL


    // console.log(cart)


    return (
        <div className="checkout">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Checkout page</title>
                <link rel="canonical" href="/checkout" />
            </Helmet>
            <section className="py-5">
                <div className="container px-4 px-lg-5 my-5">
                    <div className="row">
                        <div className="col-md-5 order-md-2 mb-4 summaryOrderCard">
                            <h4 className="d-flex justify-content-between align-items-center mb-3">
                                <span className="text-muted">Order summary</span>
                                <span className="badge badge-secondary badge-pill">3</span>
                            </h4>
                            <ul className="list-group mb-3">

                                {/* {cartItems.length === 0 && <div className='emptyCart'>Cart is empty</div>}  */}
                                {product.map((item) => (

                                    <li key={item.id} className="list-group-item d-flex justify-content-between lh-condensed">
                                        <div>
                                            <h6 className="my-0 pb-3">{item.du}</h6>
                                            <small className="text-muted">{item.title}</small>
                                        </div>
                                        <span className="qtyWraper text-muted d-flex justify-content-center align-items-center">
                                            <button onClick={handleDecrement} className="de_btn">-</button>
                                            <div className="qty text-center">{count}</div>
                                            <button onClick={handleIncrement} className="inc_btn">+</button>
                                        </span>
                                        <span id="priceVal" className="text-muted">£{item.price * count}</span>
                                        <span className="text-muted">
                                            <button className='delete' type='button'>X</button>
                                        </span>
                                    </li>

                                ))}





                                <li className="list-group-item d-flex justify-content-between">
                                    <input type="text" className="form-control" id="Cupon" placeholder="Apply Coupon" required />
                                </li>
                                <li className="list-group-item d-flex justify-content-between">
                                    <span>Total (Pound)</span>
                                    <strong><strong>£0</strong></strong>
                                </li>
                            </ul>
                            <div className='row'>
                                <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                    <img src="/Images/know.png" alt="aaa" className='securedImage' />
                                </div>
                                <div className='col-lg-12'>
                                    <p className='tellThem'>
                                        By clicking “Purchase”, you agree to the COSMA Use Terms and authorize your payment method to be saved and charged on a recurring basis until you cancel, which you can do after the first three months of subscription. You understand how your subscription works and how to cancel.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 order-md-1">
                            <form className="needs-validation">
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label for="cc-name" className="form-label">First Name</label>
                                        <input type="text" className="form-control" id="cc-name" placeholder="" required />
                                        <div className="invalid-feedback">
                                            Name on card is required
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label for="cc-number" className="form-label">Last Name</label>
                                        <input type="text" className="form-control" id="cc-number" placeholder="" required />
                                        <div className="invalid-feedback">
                                            Credit card number is required
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label for="email" className="form-label">Email </label>
                                    <input type="email" className="form-control" id="email" placeholder="you@example.com" />
                                    <div className="invalid-feedback">
                                        Please enter a valid email address for shipping updates.
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label for="address" className="form-label">Billing Address </label>
                                    <input type="text" className="form-control" id="address" placeholder="1234 Main St" required />
                                    <div className="invalid-feedback">
                                        Please enter your shipping address.
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label for="address" className="form-label">Post Code </label>
                                    <input type="text" className="form-control" id="address" placeholder="Post Code" required />
                                    <div className="invalid-feedback">
                                        Please enter your shipping address.
                                    </div>
                                </div>


                                <hr className="mb-4" />

                                <h4 className="mb-3 payTitle">Payment</h4>

                                <div className="d-block my-3">
                                    <div className="form-check d-flex justify-content-satrt align-items-center">
                                        <input id="paypal" name="paymentMethod" type="radio" className="form-check-input" required checked />
                                        <label className="form-check-label payapal" for="paypal">
                                            <img src="/Images/paypal.png"  alt="ss"/>
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label for="cc-name" className="form-label">Name on card</label>
                                        <input type="text" className="form-control" id="cc-name" placeholder="" required />
                                        <small className="text-muted">Full name as displayed on card</small>
                                        <div className="invalid-feedback">
                                            Name on card is required
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label for="cc-number" className="form-label">Credit card number</label>
                                        <input type="text" className="form-control" id="cc-number" placeholder="" required />
                                        <div className="invalid-feedback">
                                            Credit card number is required
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 mb-3">
                                        <label for="cc-expiration" className="form-label">Expiration</label>
                                        <input type="text" className="form-control" id="cc-expiration" placeholder="" required />
                                        <div className="invalid-feedback">
                                            Expiration date required
                                        </div>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <label for="cc-expiration" className="form-label">CVV</label>
                                        <input type="text" className="form-control" id="cc-cvv" placeholder="" required />
                                        <div className="invalid-feedback">
                                            Security code required
                                        </div>
                                    </div>
                                </div> */}
                                <hr className="mb-4" />
                                <button className="btn btn-dark px-4 rounded-pill" type="button">Place Order</button>

                            </form>
                        </div>
                    </div>
                    {/* <Frame/> */}
                </div>
            </section>

        </div>

    )

}
