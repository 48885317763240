import Hero from '../../Components/SubscribePage/Hero';
import Pricing from '../../Components/Pricing/index'
import { Helmet } from "react-helmet";
import axios from 'axios';
import React from 'react';
import Faq from '../../Components/SubscribePage/Faq';
import './healthcarePro.css';
import Newsletter from 'Components/Newsletter';
import Loading from 'Components/ContentLoading';

// news letter
const heading ="Subscribe to COSMA today";
const shortDes ="Subscribe to get more offers to get a COSMA license to support your dementia patients.";

export default function HealthCarePro() {


    const [data, setData] = React.useState(null);
    const [dataBreadcumbs, setDataBreadcumbs] = React.useState(null);
    const [dataHome, setDataHome] = React.useState(null);
    const [dataPricing, setDataPricings] = React.useState(null);
    const [dataPricingOfferOne, setDataPricingsOfferOne] = React.useState(null);
    const [dataPricingOfferTwo, setDataPricingsOfferTwo] = React.useState(null);
    const [dataPricingOfferThree, setDataPricingsOfferThree] = React.useState(null);
    const [dataFaq, setFaq] = React.useState(null);
    const [dataSeo, setDataSeo] = React.useState(null);

    const APIData = "https://cosmatherapy.co.uk/wp-json/wl/v1/service_singleconten";
    const APIBreadcumbs = "https://cosmatherapy.co.uk/wp-json/wl/v1/breadcrumbs";
    const APIHome = "https://cosmatherapy.co.uk/wp-json/wl/v1/home_all_contents";
    const APIPricing = "https://cosmatherapy.co.uk/wp-json/wp/v2/subscribe";
    const APIPricingOfferOne = "https://cosmatherapy.co.uk/wp-json/wp/v2/single_subscriber";
    const APIPricingOfferTwo = "https://cosmatherapy.co.uk/wp-json/wp/v2/small_businesses";
    const APIPricingOfferThree = "https://cosmatherapy.co.uk/wp-json/wp/v2/medium_business";
    const APIFaq = "https://cosmatherapy.co.uk/wp-json/wl/v1/faqs";
    const APISeo = "https://cosmatherapy.co.uk/wp-json/wp/v2/seo";

    React.useEffect(() => {
        axios.get(APISeo).then((response) => {
            setDataSeo(response.data);
        });
        axios.get(APIData).then((res) => {
            setData(res.data);
        });
        axios.get(APIBreadcumbs).then((res) => {
            setDataBreadcumbs(res.data);
        });
        axios.get(APIHome).then((res) => {
            setDataHome(res.data);
        });
        axios.get(APIPricing).then((rese) => {
            setDataPricings(rese.data);
        });
        axios.get(APIPricingOfferOne).then((rese) => {
            setDataPricingsOfferOne(rese.data);
        });
        axios.get(APIPricingOfferTwo).then((rese) => {
            setDataPricingsOfferTwo(rese.data);
        });
        axios.get(APIPricingOfferThree).then((rese) => {
            setDataPricingsOfferThree(rese.data);
        });
        axios.get(APIFaq).then((rese) => {
            setFaq(rese.data);
        });

      }, []);

   
    const breadcumbss = dataBreadcumbs;
    const homeAllData = dataHome;
    const Seo = dataSeo;
      if(!Seo){return <Loading/>}
      if (!breadcumbss) { return null }
    if(!data) {return "loading"}
    
      

      const Title = breadcumbss[3].title;
      const Description = breadcumbss[3].content.replace(/(<([^>]+)>)/gi, "");
      const bgImage = breadcumbss[3].featured_image.medium;
      const {meta_description, meta_keywords} = Seo[0].acf.healthcare_professionals;
    return (
        <div className='HealthCareProWraper'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Healthcare professionals</title>
                <meta name="description" content={meta_description} />
                <meta name="keywords" content={meta_keywords}/>
                <link rel="canonical" href="/healthcare-professionals" />
            </Helmet>
            <Hero bgImage={bgImage} Title={Title} Description={Description} />
            {/* process */}
            <div className='servicesContWrap'>
                <div>
                    <div className='processList'>
                        <div className='container'>
                            <div className='processItem'>
                                <div className='row'>
                                    <div className='col-lg-7 col-md-7 col-sm-6 col-12'>
                                        <div className='processItemContent RightOne'>
                                            <h1 className='num'>01</h1>
                                            <h1>{data[0].process_one.process_title}</h1>
                                            <p>
                                                {
                                                    data[0].process_one.process_description
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-lg-5 col-md-5 col-sm-6 col-12'>
                                        <div className='processItemImage'>
                                            <img src={data[0].process_one.process_image.url} alt="tets" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Newsletter heading = {heading} shortDes={shortDes} homeAllData={homeAllData}/>                                
                        <div className='container'>
                            <div className='processItem sssdss'>
                                <div className='row'>
                                    <div className='col-lg-5 col-md-5 col-sm-6 col-12'>
                                        <div className='processItemImage'>
                                            <img src={data[0].process_two.process_two_image.url} alt="tets" />
                                        </div>
                                    </div>
                                    <div className='col-lg-7 col-md-7 col-sm-6 col-12'>
                                        <div className='processItemContent LeftOne'>
                                            <h1 className='num'>02</h1>
                                            <h1>{data[0].process_two.process_two_title} </h1>
                                            <p>

                                            {
                                                    data[0].process_two.process_two_description
                                                }

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end */}
            <Pricing 
                dataPricing={dataPricing} 
                dataPricingOfferOne={dataPricingOfferOne}
                dataPricingOfferTwo={dataPricingOfferTwo}
                dataPricingOfferThree={dataPricingOfferThree}
            />
            <Faq dataFaq={dataFaq}/>




        </div>
    )
}