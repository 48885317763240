/* eslint-disable no-debugger */

import * as OrganizationAction from '../../Types/organizationTypes';
import axios from 'axios';

const baseUrl =  "https://test.cosmaonline.com/cosma/api";
const token = localStorage.getItem("access_token");

export const fetchOrganization=()=>{
    return function(dispatch){
        axios.get(baseUrl + '/Org/GetOrganizations', {
                headers: {
                    'Authorization': 'bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }
        )
        .then(res=>
            {
                const allOrganization = res.data;
                dispatch({
                  type:OrganizationAction.ORG_LIST,
                  payload:allOrganization
                 });
            })
        .catch(err=>console.log(err));  
    }
}

export const GETOrganizationById = (orgId) => {
  return function (dispatch) {
    axios
      .get(baseUrl + "/Org/GetOrganizationById?Id=" + orgId, {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        const organization = response.data;
        console.log(organization);
        dispatch({
          type: OrganizationAction.ORG_EDIT,
          payload: organization,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const CREATEOrganization = (organization) => {
  debugger;
  return function (dispatch) {
  axios
    .post(baseUrl + "/Org/Save", organization, {
      headers: {
        Authorization: "bearer " + token,
        "content-type": "application/json",
      },
    })
    .then(function () {
      dispatch({
        type: OrganizationAction.ORG_ADD,
        payload: organization,
       });
    })
    .catch(function (error) {
      console.log(error);
    });
  }
};

export const deleteOrganization = (id) => {
  axios
    .delete(baseUrl + "/Org/Delete?Id=" + id, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => console.log(res))
    .catch((err) => console.log(err));

  return {
    type: OrganizationAction.ORG_DELETE,
    payload: id,
  };
};
