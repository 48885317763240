
import { Link } from 'react-router-dom';
import './blogPage.css';

export default function BlogList(props) {
    const data = props.dataPosts;
    if (!data) { return null }


    return (
        <div className='BlogList'>
            {data.map(item => (
                <div className="card mb-5" key={item.id}>
                    <div className="bg-image hover-overlay" data-mdb-ripple-color="light">
                        <img src={item.featured_image.large} className="blogImage img-fluid" alt={item.title}/>
                        <a href="/">
                            <div className="mask"></div>
                        </a>
                    </div>

                    <div className="card-body">
                        <h5 className="card-title blogTitle"><Link to={`/blog/${item.id}`}>
                            {item.title}
                        </Link></h5>
                        <p className="card-text">
                            Artificial intelligence is not a new concept. It first emerged in the 1950s when Alan Turing published his article, "Intelligent Machinery." However, it wasn't until recently that AI became part of
                        </p>

                        <Link to={`/blog/${item.id}`} className="blogBtn btn-primary">Read More</Link>
                    </div>
                    <div className="card-footer">2 days ago</div>
                </div>
            ))}


        </div>
    )
}