import './subscribePage.css';


export default function Faqs(props) {

    const data = props.dataFaq;
    if (!data) { return null }


    console.log(data)
    return (
        <div className='FaqWrap'>
            <div className='container'>
                <div className='FaqsContent'>
                    <h1>Frequently Asked Questions</h1>
                    <div class="accordion" id="accordionPanelsStayOpenExample">
                        {data.map(item => (
                            <div key={item.id} class="accordion-item faqItem">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" id='faqBtn' type="button" data-mdb-toggle="collapse"
                                        data-mdb-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                            {item.title}
                                    </button>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne">
                                    <div class="accordion-body">
                                       {item.content.replace(/(<([^>]+)>)/gi, "")}
                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
            </div>
        </div>
    )
}