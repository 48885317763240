import { Link } from 'react-router-dom'
import Loading from 'Components/ContentLoading';
import './pricing.css'
import './reset.min.css'

export default function Pricing(props) {


    const dataPricing = props.dataPricing;
    const dataPricingOfferOne = props.dataPricingOfferOne;
    const dataPricingOfferThree = props.dataPricingOfferThree;
    const dataPricingOfferTwo = props.dataPricingOfferTwo;
    if(!dataPricing) {return <Loading/>}
    if(!dataPricingOfferOne) {return <Loading/>}
    if(!dataPricingOfferThree) {return <Loading/>}
    if(!dataPricingOfferTwo) {return <Loading/>}

    
    return (
        <div className='PricingWrap'>
            <div className="container-fluid">
                <section className="text-center">
                    <h4 className="mb-4 pricingTitle"><strong>Learn More About COSMA Packages</strong></h4>

                    <div className="pricing-table">
                        <div className="card">
                            <h6 className="type">{dataPricing[2].acf.other_pricing_title}</h6>
                            <div className="price"><h1>What we offer</h1></div>
                            <ul className="details">
                            {dataPricingOfferOne.map(item => (
                                <li key={item.id}>{item.name}</li>
                            ))}
                            </ul>
                            <Link to={dataPricing[2].acf.link}><div className="buy-button">
                                <h3 className="btn">Subscribe</h3>
                            </div></Link>
                        </div>

                        <div className="card">
                            <h6 className="type">{dataPricing[1].acf.other_pricing_title}</h6>
                            <div className="price"><h1>what we offer</h1></div>
                            <ul className="details">
                            {dataPricingOfferTwo.map(item => (
                                <li key={item.id}>{item.name}</li>
                            ))}
                            </ul>
                            <Link to={dataPricing[1].acf.link}><div className="buy-button">
                                <h3 className="btn">Contact us</h3>
                            </div></Link>
                        </div>

                       {/*  <div className="card">
                            <h6 className="type">{dataPricing[0].acf.other_pricing_title}</h6>
                            <div className="price"><h1>what we offer</h1></div>
                            <ul className="details">
                            {dataPricingOfferThree.map(item => (
                                <li key={item.id}>{item.name}</li>
                            ))}
                            </ul>
                            <Link to={dataPricing[0].acf.link}>
                            <div className="buy-button">
                                <h3 className="btn">Contact us</h3>
                            </div></Link>
                        </div> */}

                    </div>


                </section>
            </div>
        </div>
    )
}