
import * as OrganizationAction from '../../Types/organizationTypes';
import axios from 'axios';

const baseUrl =  "https://test.cosmaonline.com/cosma/api";
const token = localStorage.getItem("access_token");

export const fetchPatients=()=>{
    return function(dispatch){
        axios.get(baseUrl + '/Patient/GetPatient', {
                headers: {
                    'Authorization':'Bearer ' + token,
               }
            }
        )
        .then(res=>
            {
                const patients = res.data;
                dispatch({
                 type:OrganizationAction.ORG_PATIENT_LIST,
                 payload:patients
                });
            })
        .catch(err=>console.log(err));  
    }
}

export const GETPatientsById = (Id) => {
  return function (dispatch) {
    axios
      .get(baseUrl + "/Patient/GETPatientsById?Id=" + Id, {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        const patient = response.data;
        console.log(patient);
        dispatch({
          type: OrganizationAction.ORG_EDIT_PATIENT,
          payload: patient,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const CREATEPatients = (patient) => {
  axios
    .post(baseUrl + "Patient/Save", patient, {
      headers: {
        Authorization: "bearer " + token,
        "content-type": "application/json",
      },
    })
    .then(function () {
      return {
        type: OrganizationAction.ORG_ADD_PATIENT,
        payload: patient,
      };
    })
    .catch(function (error) {
      console.log(error);
    });
};



export const deletePatients=(id)=>{
  var OPTIONS = {
    url: baseUrl + "/Patient/Delete",
    method: "DELETE",
    data:{org_id:id},
    headers: {
      "content-type": "application/json",
    },
  }

axios(OPTIONS).then(res=>console.log(res)).catch(err=>console.log(err));

  return {
      type:OrganizationAction.ORG_DELETE_PATIENT,
      payload:id,
  }
}