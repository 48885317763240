
import * as OrganizationAction from '../../Types/organizationTypes';
import axios from 'axios';

const baseUrl =  "https://test.cosmaonline.com/cosma/api";
const token = localStorage.getItem("access_token");

export const fetchOrgUser=()=>{
    return function(dispatch){
        axios.get(baseUrl + '/OrgUser/GetOrgUser', {
                headers: {
                    'Authorization': 'bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }
        )
        .then(res=>
            {
                const orgUser = res.data;
                dispatch({
                  type:OrganizationAction.ORG_USER_LIST,
                  payload:orgUser
                 });
            })
        .catch(err=>console.log(err));  
    }
}
export const GETOrgUserById = (Id) => {
  return function (dispatch) {
    axios
      .get(baseUrl + "/OrgUser/GETOrgUserById?Id=" + Id, {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        const orgUsers = response.data;
        console.log(orgUsers);
        dispatch({
          type: OrganizationAction.ORG_EDIT_USER,
          payload: orgUsers,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const CREATEOrgUser = (orgUser) => {
  axios
    .post(baseUrl + "/Doctor/Save", orgUser, {
      headers: {
        Authorization: "bearer " + token,
        "content-type": "application/json",
      },
    })
    .then(function () {
      return {
        type: OrganizationAction.ORG_ADD_USER,
        payload: orgUser,
      };
    })
    .catch(function (error) {
      console.log(error);
    });
};
export const deleteOrgUser=(id)=>{
  var OPTIONS = {
    url: "/OrgUser/GetOrgUser",
    method: "DELETE",
    data:{org_id:id},
    headers: {
      "content-type": "application/json",
    },
  }

axios(OPTIONS).then(res=>console.log(res)).catch(err=>console.log(err));

  return {
      type:OrganizationAction.ORG_DELETE_USER,
      payload:id,
  }
}