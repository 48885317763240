import * as UploadedAction from '../../Types/UploadedUITypes';

const initialState={
    calenders:[],
    loadCalender:[],
    notification:[],
    ackNotification:[],
    viewBagset:[],
}

const MedicationReminderReducer=(state=initialState, action)=>{

    switch(action.type){
        case UploadedAction.MEDREMINDERCAL_LIST:return{
            ...state,
            calenders:action.payload
        }
        case UploadedAction.MEDREMINDERCAL_MONTH_LIST:return{
            ...state,
            loadCalender:action.payload
        }
        case UploadedAction.MEDREMINDERCAL_NOTIFY_LIST:return{
            ...state,
            notification:action.payload,          
        }
        case UploadedAction.MEDREMINDERCAL_ACKNOTIFY_LIST:return{
            ...state,
            ackNotification:action.payload
        }
        case UploadedAction.MEDREMINDERCAL_VIEWBAG_LIST:return{
            ...state,
            viewBagset:action.payload
        }
        default:return state;
    }
}

export default MedicationReminderReducer;