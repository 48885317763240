//FAMILY UI
export const FAMILY_LIST = 'FAMILY_LIST'
export const FAMILY_ADD = 'FAMILY_ADD'
export const FAMILY_EDIT = 'FAMILY_EDIT'
export const FAMILY_DELETE = 'FAMILY_DELETE'

//EVENTS UI
export const EVENTS_LIST = 'EVENTS_LIST'
export const EVENTS_ADD = 'EVENTS_ADD'
export const EVENTS_EDIT = 'EVENTS_EDIT'
export const EVENTS_DELETE = 'EVENTS_DELETE'

//ITEMS UI

export const ITEMS_LIST = 'ITEMS_LIST'
export const ITEMS_ADD = 'ITEMS_ADD'
export const ITEMS_EDIT = 'ITEMS_EDIT'
export const ITEMS_DELETE = 'ITEMS_DELETE'

//SCHEDULE UI

export const SCHEDULE_LIST = 'SCHEDULE_LIST'
export const SCHEDULE_ADD = 'SCHEDULE_ADD'
export const SCHEDULE_EDIT = 'SCHEDULE_EDIT'
export const SCHEDULE_DELETE = 'SCHEDULE_DELETE'

//BEHAVIOURAL

export const BEHAVIOURAL_LIST = 'BEHAVIOURAL_LIST'
export const BEHAVIOURAL_ADD = 'BEHAVIOURAL_ADD'
export const BEHAVIOURAL_EDIT = 'BEHAVIOURAL_EDIT'
export const BEHAVIOURAL_DELETE = 'BEHAVIOURAL_DELETE'

//MEDICATION REMINDER

export const MEDREMINDERCAL_LIST = 'MEDREMINDERCAL_LIST'
export const MEDREMINDERCAL_MONTH_LIST = 'MEDREMINDERCAL_MONTH_LIST'
export const MEDREMINDERCAL_NOTIFY_LIST = 'MEDREMINDERCAL_NOTIFY_LIST'
export const MEDREMINDERCAL_ACKNOTIFY_LIST = 'MEDREMINDERCAL_ACKNOTIFY_LIST'
export const MEDREMINDERCAL_VIEWBAG_LIST = 'MEDREMINDERCAL_VIEWBAG_LIST'

//SONGS UI
export const SONGS_LIST = 'SONGS_LIST'
export const SONGS_ADD = 'SONGS_ADD'
export const SONGS_EDIT = 'SONGS_EDIT'
export const SONGS_DELETE = 'SONGS_DELETE'


