import * as DashboardAction from '../../Types/dashboardTypes';

const initialState={
    report:'',
    patientReport:[],
    cognativeScore:[],
    id:'',
}

const patientDashoardReducers=(state=initialState, action)=>{

    switch(action.type){
        case DashboardAction.GAMEREPORTS_LIST:return{
            ...state,
            patientReport:action.payload
        }
        case DashboardAction.GAMEREPORTS_ADD:return{
            ...state,
            report:action.payload
        }
        case DashboardAction.GAMEREPORTS_EDIT:return{
            ...state,
            report:action.payload,          
        }
        case DashboardAction.GAMEREPORTS_DELETE:return{
            ...state
        }
        case DashboardAction.COGNATIVESCORE_ID:return{
            ...state,
            cognativeScore:action.payload
        }
        default:return state;
    }
}

export default patientDashoardReducers;