import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//import DialogContentText from '@material-ui/core/DialogContentText';
import CardHeader from "../../components/Card/CardHeader.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  // InputAdornment,
  // Checkbox,
  // FormControlLabel,
  // FormLabel,
  // Grid,
  makeStyles, TextField,
  // MenuItem,
  // Select,
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
// import CustomInput from "components/CustomInput/CustomInput";
// import { Check } from "@material-ui/icons";
// import { CREATEOrganization } from "actions/OrgActions/organizationAction";
import {  useDispatch, useSelector } from "react-redux";
// import { GETOrganizationById } from "actions/OrgActions/organizationAction";
import { useNavigate  } from "react-router";
import { CREATEOrganization } from "../../actions/OrgActions/organizationAction";
// import CustomInput from "components/CustomInput/CustomInput";
// import Email from "@material-ui/icons/Email";
// import { LocationCity } from "@material-ui/icons";
// import { useForm } from "react-hook-form";
// import { useNavigate  } from "react-router";
// import CustomInput from "components/CustomInput/CustomInput";

const styles = {
  garageTitle: {
    display: "inline-block",
  },
  rightText: {
    display: "inline-block",
    float: "right",
    color: "#FFFFFF",
  },
  textMargin: {
    marginTop: "10px",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      textAlign: "right",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const InitialState={
  "id": 0,
  "name": "",
  "contactEmail": "",
  "address1": "",
  "address2": "",
  "city": "",
  "state": "",
  "country": "",
  "isActive": true,
  "modifiedTime": "2021-10-12T08:03:10.107Z",
  "modifiedBy": ""
}

const useStyles = makeStyles(styles);

function CreateModal() {
  const [open, SetOpen] = useState(false);
  const [org, SetOrg] = useState(InitialState);
  const [error, SetError] = useState();
  const history = useNavigate ();
  const classes = useStyles();
  const organization =useSelector(state=>state.Org.organization.id);
  console.log(organization);
  const dispatch=useDispatch();
  useEffect(()=>{
    // dispatch(CREATEOrganization(org));
  },[]);


  const handleClickOpen = () => {
    SetOpen(true);
  };
  const {name, contactEmail,address1,address2,city,state,country} = org;
  const handleInputChange=(e)=>{
   let {name , value } = e.target;
   SetOrg({ ...org, [name]: value });
  }
  const formSubmitHandler= (e)=>{
    // e.preventDefault;
    if(!name || !contactEmail || !address1 || !country)
    {
      SetError("Please input All Fields")
    }else{
      dispatch(CREATEOrganization(org));
      history.push("/admin/organization");
      SetOpen(false);
    }
  }
  const handleClose = () => {
    SetOpen(false);
  };

  return (
    <>
    <div>
    <Button
    className={classes.rightText}
    variant="outlined"
    onClick={handleClickOpen}
  >
    Add New
  </Button>
  </div>
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="Add-Organization"
        className={classes.cardTitleWhite}
      >

        <CardHeader color="success">
          <DialogTitle id="Add-Organization" className={classes.textMargin}>
            {" "}
            Add Organization
          </DialogTitle>
        </CardHeader>
    <form onSubmit={formSubmitHandler}>
        <DialogContent>
          <GridContainer>
          <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                  {error && <h5 style={{color:"red"}}>{error}</h5>}
              </GridItem>
          </GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                id="standard-basic"
                name="name"
                label="Name"
                value={name}
                onChange={handleInputChange}
                // variant="outlined"
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                id="standard-basic"
                name="contactEmail"
                label="Contact Email"
                value={contactEmail}
                onChange={handleInputChange}
                fullWidth
                // variant="outlined"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="standard-basic"
                name="address1"
                label=" First Line Address"
                value={address1}
                onChange={handleInputChange}
                fullWidth
                // variant="outlined"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="standard-basic"
                name="address2"
                label="Second Line Address"
                value={address2}
                onChange={handleInputChange}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <TextField
                autoFocus
                margin="dense"
                id="standard-basic"
                name="city"
                label="City"
                value={city}
                onChange={handleInputChange}
                fullWidth
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <TextField
                autoFocus
                margin="dense"
                id="standard-basic"
                name="state"
                label="State"
                value={state}
                onChange={handleInputChange}
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="standard-basic"
                name="country"
                label="Country"
                value={country}
                onChange={handleInputChange}
                fullWidth
              />
            </GridItem>
          </GridContainer>
          <hr />
        </DialogContent>

        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </form>
      </Dialog>
    </div>
    </>
  );
}
 export default CreateModal;
