import * as UploadedAction from '../../Types/UploadedUITypes';

const initialState={
    event:'',
    events:[],
    id:'',
}

const eventsReducers=(state=initialState, action)=>{

    switch(action.type){
        case UploadedAction.EVENTS_LIST:return{
            ...state,
            events:action.payload
        }
        case UploadedAction.EVENTS_ADD:return{
            ...state,
            event:action.payload
        }
        case UploadedAction.EVENTS_EDIT:return{
            ...state,
            event:action.payload,          
        }
        case UploadedAction.EVENTS_DELETE:return{
            ...state
        }
        default:return state;
    }
}

export default eventsReducers;