import { IoMenuOutline } from "react-icons/io5";
import React from 'react';
import Button from '../../Button';
import "./header.css";
import { Link } from 'react-router-dom';


export default function Header(to, BtnContent) {
    return (
        <div className="customContainer">
            <nav className="navbar navbar-expand-lg navbar-dark bg-custom">
                <a className="navbar-brand me-5" href="/">
                    <img
                        src="/Images/cosma.png"
                        height="48"
                        alt="Cosma Logo"
                        loading="lazy"
                    />
                    <span className='ms-3 brandName'>
                        Cosma Therapy
                    </span>
                </a>

                <button
                    className="navbar-toggler"
                    type="button"
                    data-mdb-toggle="collapse"
                    data-mdb-target="#navbarButtonsExample"
                    aria-controls="navbarButtonsExample"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <IoMenuOutline className='barContent' />
                </button>


                <div className="collapse navbar-collapse" id="navbarButtonsExample">

                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/features">Features</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/subscribe">Subscribe</Link>
                        </li>
                        {/* <li class="nav-item dropdown">
                            <a
                                class="nav-link dropdown-toggle"
                                href="#"
                                id="navbarDropdownMenuLink"
                                role="button"
                                data-mdb-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Services
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li>
                                    <Link to="/">
                                        <a class="dropdown-item">Healthcare Professionals</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/healthcare-organisations">
                                        <a class="dropdown-item">Healthcare Organisations</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/research-organisations">
                                        <a class="dropdown-item">Research Rganisations</a>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/dementia-communities">
                                        <a class="dropdown-item">Dementia Communities</a>
                                    </Link>
                                </li>
                            </ul>
                        </li> */}
                        <li className="nav-item">
                            <Link className="nav-link" to="/blog">Blog</Link>
                        </li>
                    </ul>
                    <div className="d-flex align-items-center ml-20">
                        <Link className='csCustomBtn me-3 bookHeaderBtn' to='/bookdemo'>Book Demo</Link>
                        <a href="https://portal.cosmaapp.com"><h3 className="csCustomBtn me-3 BtnContent">Login</h3></a>
                        {/* <Button to="/login" BtnContent='Login' />  */}
                    </div>
                </div>
            </nav>
        </div>

    )
}