import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CardHeader from "../../../components/Card/CardHeader.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";
import PictureUpload from "../../../components/CustomUpload/PictureUpload";
import { useNavigate  } from "react-router";
import { useDispatch } from "react-redux";
import { CREATEEvents } from "../../../actions/UploadedUI/EventsAction";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";


const styles = {
  rightText: {
    display: "inline-block",
    float: "right",
    color: "#FFFFFF",
  },
  textMargin: {
    marginTop: "10px",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      textAlign: "right",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": { 
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};


const InitialState={
  "id": 0,
  "name": "",
  "location": "",
  "year": '',
  "photo": "",
  "userId": 0
}

const useStyles = makeStyles(styles);

export default function FormDialog() {
  const [open, SetOpen] = useState(false);
  const [event, SetEventes] = useState(InitialState);
  const [error, SetError] = useState();
  const history = useNavigate ();
  const classes = useStyles();
  // const organization =useSelector(state=>state.Org.organization.id);
  // console.log(organization);
   const dispatch=useDispatch();
  // useEffect(()=>{
  //   // dispatch(CREATEOrganization(org));
  // },[]);


  const handleClickOpen = () => {
    SetOpen(true);
  };
  const {name, location,year,photo } = event;
  const handleInputChange=(e)=>{
   let {name , value } = e.target;
   SetEventes({ ...event, [name]: value });
  }
  const formSubmitHandler= (e)=>{
    // e.preventDefault;
    if(!name || !year )
    {
      SetError("Please input All Fields")
    }else{
      dispatch(CREATEEvents(event));
      history.push("/admin/EventsUI");
      SetOpen(false);
    }
  }
  const handleClose = () => {
    SetOpen(false);
  };


  return (
    <div>
      <Button
        className={classes.rightText}
        variant="outlined"
        onClick={handleClickOpen}
      >
        {/* <Icon color="primary">add_circle</Icon> */}
        Add New
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="Add-Family"
        className={classes.cardTitleWhite}
      >
        <CardHeader color="primary">
          <DialogTitle id="Add-Family" className={classes.textMargin}>
            {" "}
            Add Event
          </DialogTitle>
        </CardHeader>
        <form onSubmit={formSubmitHandler}>
        <GridContainer>
        <DialogContent xs={8} sm={8} md={8}>
          <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                  {error && <h5 style={{color:"red"}}>{error}</h5>}
              </GridItem>
          </GridContainer>
          <PictureUpload id="photo" name="photo" value={photo} onChange={handleInputChange} />
          <TextField id="UserId" name="userId" value={localStorage.getItem("userId")} hidden/>
        {/* <ImageUploadCard cardName="Input Image" imageGallery={galleryImageList} /> */}
        </DialogContent>
        <DialogContent xs={8} sm={8} md={8}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            label="Name"
            name="name"
            value={name}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="location"
            label="Location"
            name="location"
            value={location}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="year"
            label="Year"
            name="year"
            value={year}
            onChange={handleInputChange}
            fullWidth
          />
        </DialogContent>
        </GridContainer>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
