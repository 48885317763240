
import * as UploadedAction from '../../Types/UploadedUITypes';
import axios from 'axios';

const baseUrl =  "https://test.cosmaonline.com/cosma/api";
const token = localStorage.getItem("access_token");

export const fetchSchedule=()=>{
    return function(dispatch){
        axios.get(baseUrl + '/Schedule/GetSchedules', {
                headers: {
                    'Authorization': 'bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }
        )
        .then(res=>
            {
                const schedule = res.data;
                dispatch({
                  type:UploadedAction.SCHEDULE_LIST,
                  payload:schedule
                 });
            })
        .catch(err=>console.log(err));  
    }
}

export const GETScheduleById = (Id) => {
  return function (dispatch) {
    axios
      .get(baseUrl + "/Schedule/GetSchedules?Id=" + Id, {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        const schedule = response.data;
        // console.log(schedule);
        dispatch({
          type: UploadedAction.SCHEDULE_EDIT,
          payload: schedule,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const CREATESchedule = (schedule) => {
  axios
    .post(baseUrl + "​​/Schedule/CreateSchedule", schedule, {
      headers: {
        Authorization: "bearer " + token,
        "content-type": "application/json",
      },
    })
    .then(function () {
      return {
        type: UploadedAction.SCHEDULE_ADD,
        payload: schedule,
      };
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const deleteSchedule = (id) => {
  axios
    .post(baseUrl + "/Schedule​/Delete?Id=" + id, {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => console.log(res))
    .catch((err) => console.log(err));
  return {
    type: UploadedAction.SCHEDULE_DELETE,
    payload: id,
  };
};
