import * as OrganizationAction from '../../Types/organizationTypes';

const initialState={
    allPatients:[],
    patient:'',
    id:'',
}

const patientsReducer=(state=initialState,action)=>{
    switch(action.type){
        case OrganizationAction.ORG_PATIENT_LIST:
        return{
            ...state,
            allPatients:action.payload
        }
        case OrganizationAction.ORG_EDIT_PATIENT:
        return{
            patient:action.payload,
            id:action.id,
        }
        case OrganizationAction.ORG_ADD_PATIENT:
            return{
                ...state,
                patient:action.payload
        }
        case OrganizationAction.ORG_DELETE_PATIENT:
            return{
            ...state
        }
        default: return state;
    }
}

// const patientsReducer=(state=initialState, action)=>{

//     switch(action.type){
//         // case ADD_PASSCAT:return{
//         //     ...state,
//         //     category:action.payload
//         // }
//         case OrganizationAction.ORG_PATIENT_LIST:return{
//             ...state,
//             allPatients:action.payload
//         }
//         // case EDIT_PASSCAT:return{
//         //     ...state,
//         //     category:action.payload,
//         //     id:action.id,
//         //     action:'Edit'
//         // }
//         // case UPDATE_PASSCAT:return{
//         //     ...state,
//         //     category:action.payload,
//         //     msg:"Password Category Updated Successfully"
          
//         // }
//         case OrganizationAction.ORG_DELETE_PATIENT:return{
//             ...state
//         }
//         default:return state;
//     }
// }

export default patientsReducer;