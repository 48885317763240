import { Helmet } from "react-helmet";
import Brain from '../../Components/FeaturePage/brain';
import HeroSection from '../../Components/FeaturePage/Hero';
import Process from '../../Components/FeaturePage/process';
import WhatDone from '../../Components/FeaturePage/WhatDone';
import Pricing from '../../Components/Pricing';
import axios from 'axios';
import React from 'react';
import './feature.css';
import Loading from 'Components/ContentLoading';


export default function Feature(props) {


    const [dataFeaturs, setDataFeaturs] = React.useState(null);
    const [dataPricing, setDataPricings] = React.useState(null);
    const [dataPricingOfferOne, setDataPricingsOfferOne] = React.useState(null);
    const [dataPricingOfferTwo, setDataPricingsOfferTwo] = React.useState(null);
    const [dataPricingOfferThree, setDataPricingsOfferThree] = React.useState(null);
    const [dataSeo, setDataSeo] = React.useState(null);

    const APIFeatures = "https://cosmatherapy.co.uk/wp-json/wl/v1/features_all_content";
    const APIPricing = "https://cosmatherapy.co.uk/wp-json/wp/v2/subscribe";
    const APIPricingOfferOne = "https://cosmatherapy.co.uk/wp-json/wp/v2/single_subscriber";
    const APIPricingOfferTwo = "https://cosmatherapy.co.uk/wp-json/wp/v2/small_businesses";
    const APIPricingOfferThree = "https://cosmatherapy.co.uk/wp-json/wp/v2/medium_business";
    const APISeo = "https://cosmatherapy.co.uk/wp-json/wp/v2/seo";
    React.useEffect(() => {
        axios.get(APISeo).then((response) => {
            setDataSeo(response.data);
        });
        axios.get(APIFeatures).then((res) => {
            setDataFeaturs(res.data);
        });
        axios.get(APIPricing).then((rese) => {
            setDataPricings(rese.data);
        });
        axios.get(APIPricingOfferOne).then((rese) => {
            setDataPricingsOfferOne(rese.data);
        });
        axios.get(APIPricingOfferTwo).then((rese) => {
            setDataPricingsOfferTwo(rese.data);
        });
        axios.get(APIPricingOfferThree).then((rese) => {
            setDataPricingsOfferThree(rese.data);
        });
        

      }, []);
      const Seo = dataSeo;
      if(!Seo){return <Loading/>}

      const {meta_description, meta_keywords} = Seo[0].acf.features_page;

    return(
        <div className='featurePageWrap'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Features</title>
                <meta name="description" content={meta_description} />
                <meta name="keywords" content={meta_keywords}/>
                <link rel="canonical" href="/features" />
            </Helmet>
            <WhatDone dataFeaturs={dataFeaturs}/>
            <Process dataFeaturs={dataFeaturs}/>
            <HeroSection dataFeaturs={dataFeaturs} />
            <Brain dataFeaturs={dataFeaturs}/>
            <Pricing
            dataPricing={dataPricing} 
            dataPricingOfferOne={dataPricingOfferOne}
            dataPricingOfferTwo={dataPricingOfferTwo}
            dataPricingOfferThree={dataPricingOfferThree}
            />
        
        </div>
    )
}