
import React from "react";
import axios from 'axios';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
// import "./swiper-bundle.min.css";

import "./testimonial.css";


// import Swiper core and required modules
import SwiperCore, {
    Pagination, Navigation
} from 'swiper';
// install Swiper modules



SwiperCore.use([Pagination, Navigation]);

export default function Testimonial (props) {
    
    const [dataTestimoial, setDataTestimonail] = React.useState(null);
    const [dataOther, setDataOtehr] = React.useState(null);
    
    const APIData = "https://cosmatherapy.co.uk/wp-json/wl/v1/testimonials";
    const APICon = "https://cosmatherapy.co.uk/wp-json/wP/v2/other_contents";

    React.useEffect(() => {
        
        axios.get(APIData).then((res) => {
            setDataTestimonail(res.data);
        });
        axios.get(APICon).then((res) => {
            setDataOtehr(res.data);
        });



      }, []);


      if (!dataTestimoial) { return null }
      if (!dataOther) { return null }

      const {sub_heading, bg_image} =dataOther[0].acf

    return (
        <div className="testimonialWraper" style={{ backgroundImage: `url(${bg_image.url})` }}>
            <div className="container">
                <div className="heading">
                <h1>{dataOther[0].name}</h1>
                <p>{sub_heading}</p>
                </div>
                <Swiper slidesPerView={1} spaceBetween={30} loop={true} pagination={{
                    "clickable": true
                }} navigation={true} className="TestimoialSlider">
                    {dataTestimoial.map((item) => (
                        <SwiperSlide key={item.id} >
                            <div className="testimonialContentWrap">
                                <div>
                                    <img src={item.featured_image.medium} alt="ass" />
                                </div>
                                <div className="cotestn">
                                    <h1>“</h1>
                                    <p>{item.content.replace(/(<([^>]+)>)/gi, "")}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};