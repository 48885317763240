import Hero from '../../Components/SubscribePage/Hero';
import './termsofservcie.css'
import { IoCloudDownloadOutline } from "react-icons/io5";
import { Helmet } from "react-helmet";
import React from 'react';
import axios from 'axios';
import Loading from 'Components/ContentLoading'
// For Hero section
const bgImage = 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/bg-pricing.jpg';
const Title = 'Our Terms & Conditions';
const Description = '';

export default function TermsOfUse() {
    const [dataSeo, setDataSeo] = React.useState(null);
    const APISeo = "https://cosmatherapy.co.uk/wp-json/wp/v2/seo";


    React.useEffect(() => {
        axios.get(APISeo).then((response) => {
            setDataSeo(response.data);
        });
    }, []);


    const Seo = dataSeo;
    if (!Seo) { return <Loading /> }

    const { meta_description, meta_keywords } = Seo[0].acf.terms_and_conditions_page;

    return (
        <div className='termsOfWrap'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Terms of use</title>
                <meta name="description" content={meta_description} />
                <meta name="keywords" content={meta_keywords} />
                <link rel="canonical" href="/terms-of-use" />
            </Helmet>
            <Hero bgImage={bgImage} Title={Title} Description={Description} />
            <div className='downloadWrap'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                            <div className='downOne downOne1 text-center'>
                                <a rel="noreferrer" target="_blank" href='https://cosmatherapy.com/wp-content/uploads/2021/03/Terms-and-Conditions.pdf'><span><IoCloudDownloadOutline className='downIcon' /></span>T&C Download</a>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                            <div className='downOne text-center'>
                                <a rel="noreferrer" target="_blank" href='https://cosmatherapy.com/wp-content/uploads/2021/03/Cookies.pdf'><span><IoCloudDownloadOutline className='downIcon' /></span>Data Handling Download</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='terms_Wraper'>
                    <div>
                        <div className='termsOfUseContent'>
                            <h1>TERMS OF SALE</h1>

                            <div className='termsOfAllContent'>
                                <p>
                                    These Terms of Sale, together with any and all other documents referred to herein, set out the terms under which Goods are sold by Us to consumers through this website  www.cosmatherapy.com  (“Our Site”).  Please read these Terms of Sale carefully and ensure that you understand them before ordering any Goods from Our Site.  You will be required to read and accept these Terms of Sale when ordering Goods.  If you do not agree to comply with and be bound by these Terms of Sale, you will not be able to order Goods through Our Site.  These Terms of Sale, as well as any and all Contracts are in the English language only.
                                </p>
                                <ul className='joynithList'>
                                    <li>
                                        <h3>GENERAL TERMS OF SALE</h3>
                                        <p>The term “digital product” refers to paid digital products available from cosmatherapy.com</p>
                                    </li>
                                    <li>
                                        <h3>About Digital Products</h3>
                                        <p>You are not required to purchase anything for certain limited use of cosmatherapy.com or the COSMA apps. However, if you do not purchase a subscription or product, your access to COSMA content and services will be limited.
                                            COSMA reserves the right to modify the content, type and availability of any digital product at any time.Only one person may use the username and password associated with a digital product account.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Processing and Payment</h3>
                                        <p>BrainBerry will process your purchase as promptly as possible. There may be a delay in the activation of your product while payment details are verified.
                                            We accept major credit cards, PayPal and certain digital wallet services. We reserve the right to reject any order or purchase at any time.
                                            If your initial payment authorization is later revoked, your subscription, product or access will be terminated. Contact our Customer group if you believe your access was terminated in error.
                                            Unless specified otherwise in the Cancellation and Refund Policy, all charges are non-refundable.
                                            To view or change your payment method, visit <a href="https://cosmatherapy.com/login/">My Account are of cosmatherapy.com </a>
                                            COSMA may receive updated credit card information (new credit card number or updated expiration date) from your credit or debit card issuer. The disbursement of the updated credit card information is provided to us at the election of your credit card issuer. We are not responsible for the distribution of your updated credit card information. Your credit card issuer may give you the right to opt out of the update service. It is your responsibility to contact your credit card issuer with regards to your right to opt out of the update service.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Pricing</h3>
                                        <p>When you purchase a digital product, the price will be made clear during the order process. You agree to pay the price that is stated at the time of your order, as well as any applicable taxes. You also agree to the billing frequency stated at the time of your order.
                                            Discount eligibility is determined at the time of the order. Discounts cannot be applied retroactively.
                                            All prices are in United States Dollars, unless otherwise stated.
                                            COSMA reserves the right to change prices and fees at any time. We will notify you in advance if the regular rate of a product changes from what was stated at the time of your order. You will have the opportunity to accept the new price or cancel your subscription or purchase from that point forward.
                                            Applicable taxes may vary. We are not able to notify you in advance of changes in applicable taxes.
                                            If a stated price is determined by us in our sole discretion to be in error, we are not under any obligation to offer you the product at that price. We will notify you of the error and give you the opportunity to cancel your order and obtain a refund if payment has already been made.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Billing</h3>
                                        <p>Billing of Digital Subscriptions
                                            We will charge or debit your payment method at the beginning of your subscription or, if applicable, at the end of your free trial period. Billing will continue according to the cycle stated at the time of your order.
                                            All COSMA subscriptions are renewed automatically. When we renew your subscription, we will use the payment method currently associated with your account. If you redeem a gift subscription and choose to extend your subscription at the time of redemption, that subscription will renew automatically. You can view your payment method at any time in the My Account of cosmatherapy.com
                                            Currency
                                            The currency in which you will be billed will be stated during the purchase process and is determined based on your billing address.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Unpaid Charges</h3>
                                        <p>If your credit card expires or your payment method is otherwise invalid, your subscription or product will not automatically be terminated. You will remain responsible for all charges.
                                            You will be responsible for all costs we incur in connection with the collection of unpaid amounts, including court costs, attorneys fees, collection agency fees and any other associated costs.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Unpaid Charges</h3>
                                        <p>If your credit card expires or your payment method is otherwise invalid, your subscription or product will not automatically be terminated. You will remain responsible for all charges.
                                            You will be responsible for all costs we incur in connection with the collection of unpaid amounts, including court costs, attorneys fees, collection agency fees and any other associated costs.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Promotions</h3>
                                        <p>We may occasionally offer promotions. The specific terms of each promotion are stated at the time the promotion is offered. Each promotion may be different. Promotions cannot be combined.
                                            You are required to provide your payment details when you sign up for a promotion. At the end of the promotion, your subscription will automatically renew at the rates displayed at the time of purchase.
                                            We will not notify you in advance that the promotion is about to end and we will not notify you when your promotional rate has ended. To cancel and avoid being charged, you must notify us before the promotion ends.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Software Products</h3>
                                        <p>As you purchase a digital subscription that includes downloadable software, your download and use of that software may be subject to additional terms or end user license agreements.

                                        </p>
                                    </li>
                                    <li>
                                        <h3>Third Parties</h3>
                                        <p>If you have purchased your product through a third party, these Terms of Sale may not apply to you. We are not liable to you for any claims related to purchases made through third parties. Please contact the third party directly.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Cancellation and Refunds of Digital Subscriptions</h3>
                                        <p>When you cancel a subscription, you cancel only future charges associated with your subscription. You may notify us of your intent to cancel at any time, but the cancellation will become effective at the end of your current billing period.
                                            Cancellations are effective the following billing cycle. You will not receive a refund for the current billing cycle. You will continue to have the same access and benefits of your product for the remainder of the current billing period.
                                            We reserve the right to issue refunds or credits at our sole discretion. If we issue a refund or credit, we are under no obligation to issue the same or similar refund in the future.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Changes to Content or Access</h3>
                                        <p>We reserve the right to make changes to our digital products at any time. If we temporarily reduce or eliminate the charge for content or access that you are currently paying for under different terms, you will not receive a refund.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Cancellations by Us</h3>
                                        <p>We reserve the right to suspend or terminate your subscription or product for any reason, with or without notice and without further obligation. You will not be entitled to a refund in these circumstances. If any or all of our digital products are temporarily unavailable, you will not receive a refund. We reserve the right to issue refunds or credits at our sole discretion. If we issue a refund or credit, we are under no obligation to issue the same or similar refund in the future.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Cancellation During Promotional Periods</h3>
                                        <p>Certain promotions may not permit cancellation during the promotional period. You agree to the cancellation and refund terms stated at the time of purchase.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Changing your Subscription</h3>
                                        <p>When you change your subscription, you will receive a pro-rated credit toward your new subscription.
                                        </p>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div className='termsOfUseContent'>
                            <h1 className='pb-4'>END USER LICENSE AGREEMENT</h1>
                            <p className='liPara'>These Terms and Conditions, together with any and all other documents referred to herein, set out the terms of use under which you may use this website, <a href="http://www.cosmatherpay.com/">www.cosmaonline.com</a> and <a href="http://www.cosmatherpay.com/">www.cosmatherpay.com</a> (“Our Site”). Please read these Terms and Conditions carefully and ensure that you understand them. Your agreement to comply with and be bound by these Terms and Conditions is deemed to occur upon your first use of Our Site. If you do not agree to comply with and be bound by these Terms and Conditions, you must stop using Our Site immediately.</p>
                            <ul className='joynithList'>
                                <li>
                                    <h3>Definitions and Interpretation</h3>
                                    <p>In these Terms and Conditions, unless the context otherwise requires, the following expressions have the following meanings:</p>
                                </li>
                                <li className='defination'>
                                    <div className='row'>
                                        <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                            <div className='conWraper'>
                                                <p>“Account”</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 col-md-7 col-sm-7 col-7'>
                                            <div className='conWraper'>
                                                <p>means an account required for a User to access certain areas of Our Site, as detailed in Clause 4;</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className='defination'>
                                    <div className='row'>
                                        <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                            <div className='conWraper'>
                                                <p>“Blog”</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 col-md-7 col-sm-7 col-7'>
                                            <div className='conWraper'>
                                                <p>means a blog hosted on Our Site, created by a User, containing Post(s) submitted by that User;</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className='defination'>
                                    <div className='row'>
                                        <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                            <div className='conWraper'>
                                                <p>“Comment”</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 col-md-7 col-sm-7 col-7'>
                                            <div className='conWraper'>
                                                <p>means a comment on a Blog or Post on Our Site made by a User;</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className='defination'>
                                    <div className='row'>
                                        <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                            <div className='conWraper'>
                                                <p>“Content”</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 col-md-7 col-sm-7 col-7'>
                                            <div className='conWraper'>
                                                <p>means any and all text, images, audio, video, scripts, code, software, databases and any other form of information capable of being stored on a computer that appears on, or forms part of, Our Site;</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className='defination'>
                                    <div className='row'>
                                        <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                            <div className='conWraper'>
                                                <p>“Forum”</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 col-md-7 col-sm-7 col-7'>
                                            <div className='conWraper'>
                                                <p>means a discussion forum hosted on Our Site;</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className='defination'>
                                    <div className='row'>
                                        <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                            <div className='conWraper'>
                                                <p>“System”	</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 col-md-7 col-sm-7 col-7'>
                                            <div className='conWraper'>
                                                <p>means any online communications infrastructure that We make available through Our Site either now or in the future. This may include, but is not limited to, contact forms, email, live chat and Forums;</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className='defination'>
                                    <div className='row'>
                                        <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                            <div className='conWraper'>
                                                <p>“Post”</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 col-md-7 col-sm-7 col-7'>
                                            <div className='conWraper'>
                                                <p>means a post created by a User in a Blog on Our Site;</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className='defination'>
                                    <div className='row'>
                                        <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                            <div className='conWraper'>
                                                <p>“User”</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 col-md-7 col-sm-7 col-7'>
                                            <div className='conWraper'>
                                                <p>means a user of Our Site; and</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className='defination'>
                                    <div className='row'>
                                        <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                            <div className='conWraper'>
                                                <p>      “We/Us/Our”</p>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 col-md-7 col-sm-7 col-7'>
                                            <div className='conWraper'>
                                                <p>1.1.means a company registered in England under Companies House number 09544910, whose registered and main trading address is 4, Rydal Avenue, Loughborough, England, LE11 3rx.</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className='pt-5'>
                                    <h3>Information About Us</h3>
                                    <p>Access to Our Site is free of charge.
                                        It is your responsibility to make any and all arrangements necessary in order to access our Site.
                                        Access to Our Site is provided “as is” and on an “as available” basis. We may alter, suspend or discontinue Our Site (or any part of it) at any time and without notice. We will not be liable to you in any way if Our Site (or any part of it) is unavailable at any time and for any period.
                                        Links to other sites may be included on Our Site. Unless expressly stated, these sites are not under Our control.We neither assume nor accept responsibility or liability for the content of third-party sites.  The inclusion of a link to another site on Our Site is for information only and does not imply any endorsement of the sites themselves or of those in control of them.</p>
                                </li>
                                <li>
                                    <h3>Accounts</h3>
                                    <p>1 Certain parts of Our Site (including the ability to create Blogs and Posts) may require an Account in order to access them.
                                        4.2 You may not create an Account if you are under 18 years of age.
                                        4.3 When creating an Account, the information you provide must be accurate and complete. If any of your information changes at a later date, it is your responsibility to ensure that your Account is kept up-to-date.
                                        4.4 We require that you choose a strong password for your Account. It is your responsibility to keep your password safe. You must not share your Account with anyone else part from your carer and people who will support you with the therapy. If you believe your Account is being used without your permission, please contact Us immediately at Contact Us. We will not be liable for any unauthorised use of your Account.
                                        4.5 You must not use anyone elses Account without the express permission of the User to whom the Account belongs.
                                        4.6 Any personal information provided in your Account will be collected, used, and held in accordance with your rights and Our obligations under the law, as set out in Clause 12.
                                        4.7 If you wish to close your Account, you may do so at any time. Closing your Account will result in the removal of your information. Closing your Account will also remove access to any areas of Our Site requiring an Account for access.
                                        4.8 If you close your Account, any Blogs or Posts you have created on Our Site will be deleted. Any Comments you have made on other Users Blogs or Posts will be anonymised by “removing your username and profile details.</p>
                                </li>
                                <li>
                                    <h3>User Content</h3>
                                    <p>An Account is required if you wish to submit User Content. Please refer to Clause 4 for more information.
                                        The provisions of this Clause 5 apply only to the use of website account Site www.cosmaonline.com or www.cosmaapp.com as an account holder and not to the website terms of use, which is governed separately by Our Website Terms of Sale.
                                        User Content after login as an account holder includes (but is not necessarily limited to) your personal pictures, life events and pictures that are personal and private.
                                        You agree that you will be solely responsible for your User Content. Specifically, you agree, represent and warrant that you have the right to submit the User Content and that all such User Content will comply with Our Acceptable Usage Policy, detailed below in Clause 7.
                                        You agree that you will be liable to Us and will, to the fullest extent permissible by law, indemnify Us for any breach of the warranties given by you under sub-Clause 7.3. You will be responsible for any loss or damage suffered by Us as a result of such breach.
                                        If you wish to remove User Content from Our Site, the User Content in question will be deleted. Please note, however, that caching or references to your User Content may not be made immediately unavailable (or may not be made unavailable at all where they are outside of Our reasonable control).
                                        We may reject, reclassify, or remove any User Content from your account where, in Our sole opinion, it violates Our Acceptable Usage Policy, or if We receive a complaint from a third party and determine that the User Content in question should be removed as a result.</p>
                                </li>
                                <li>
                                    <h3>Intellectual Property Rights</h3>
                                    <p>With the exception of the content of Blogs and Posts (see Clause 7), all Content on Our Site and the copyright and other intellectual property rights subsisting in that Content, unless specifically labelled otherwise, belongs to or has been licensed by Us. All Content (including the content of Blogs and Posts) is protected by applicable United Kingdom and international intellectual property laws and treaties.
                                        Subject to the licence granted to Us under sub-Clause 7.4, Users retain the ownership of copyright and other intellectual property rights subsisting in the content of their Blogs, Posts and Comments (unless any part of that content is owned by a third party who has given their express permission for their material to be used).
                                        For personal use (including research and private study) only, you may:
                                        Access, view and use Our Site in a web browser (including any web browsing capability built into other types of software or app);
                                        Download Our Site (or any part of it) for caching;
                                        Print [one copy of any] page[s] from Our Site;
                                        Download, copy, clip, print, or otherwise save extracts from pages on Our Site;
                                        Save pages from Our Site for later and/or offline viewing; and
                                        View and use other Users’ Blogs, Posts and Comments in accordance with Clause
                                        You may not use any Content (including Blogs, Posts and Comments) downloaded, copied, clipped, printed or otherwise saved from Our Site for commercial purposes without first obtaining a licence to do so from Us, our licensors, or from the relevant User, as appropriate. [This does not prohibit the normal access, viewing and use of Our Site for general information purposes whether by business users or consumers].
                                        You may not systematically copy Content from Our Site with a view to creating or compiling any form of comprehensive collection, compilation, directory, or database unless given Our express permission to do so.
                                        Subject to sub-Clause[s] 6.3 [and 6.7] and Clause 7 (governing Blogs, Posts and Comments) you may not otherwise reproduce, copy, distribute, sell, rent, sub-licence, store, or in any other manner re-use Content or any other material from Our Site unless given express written permission to do so. For further information, please contact Us at Contact Us.
                                        Our status as the owner and author of the content on Our Site (or that of identified licensors or Users, as appropriate) must always be acknowledged.
                                        8 Nothing in these Terms and Conditions limits or excludes the fair dealing provisions of Chapter III of the Copyrights, Designs and Patents Act 1988 ‘Acts Permitted in Relation to Copyright Works’, covering in particular the making of temporary copies; research and private study; the making of copies for text and data analysis for non-commercial research; criticism, review, quotation and news reporting; caricature, parody or pastiche; and the incidental inclusion of copyright material.
                                        The content of Blogs, Posts, and Comments on Our Site and the copyright and other intellectual property rights subsisting therein, unless specifically labelled otherwise, belongs to or has been licensed by the User identified along with the Blog, Post, or Comment in question. All such content is protected by applicable United Kingdom and international intellectual property laws and treaties.
                                        You may copy and share (reblog) parts of other Users’ Posts and/or Comments within Our Site, provided that the original User is credited. If you wish to re-use another User’s content in any other way, you must contact the User directly and obtain their express permission to do so.
                                        Nothing in these Terms and Conditions limits or excludes the fair dealing provisions of Chapter III of the Copyright, Designs and Patents Act 1988 ‘Acts Permitted in Relation to Copyright Works’, covering in particular the making of temporary copies; research and private study; the making of copies for text and data analysis for non-commercial research; criticism, review, quotation and news reporting; caricature, parody or pastiche; and the incidental inclusion of copyright material.</p>
                                </li>
                                <li>
                                    <h3>Acceptable Usage Policy</h3>
                                    <p>You may only use Our Site (including, but not limited to, the creation of Blogs, Posts, and Comments) in a manner that is lawful. Specifically:
                                        you must ensure that you comply fully with any and all applicable local, national and international laws and/or regulations;
                                        you must not use Our Site in any way, or for any purpose, that is unlawful or fraudulent;
                                        you must not use Our Site to knowingly send, upload, or in any other way transmit data that contains any form of virus or other malware, or any other code designed to adversely affect computer hardware, software, or data of any kind; and
                                        you must not use Our Site in any way, or for any purpose, that is intended to harm any person or persons in any way.
                                        When creating Blogs, Posts, or Comments and When submitting Posts (or communicating in any other way using Our Site), you agree that you will be solely responsible for your Blog(s) and Posts and for any Comments you make anywhere on our Site and you must not submit, communicate or otherwise do anything that:
                                        you must not post, communicate, or otherwise do anything that:
                                        is sexually explicit;
                                        is obscene, deliberately offensive, hateful or otherwise inflammatory;
                                        promotes violence;
                                        promotes or assists in any form of unlawful activity; discriminates against, or is in any way defamatory of, any person, group or class of persons, race, sex, religion, nationality, disability, sexual orientation or age;
                                        is intended or otherwise likely to threaten, harass, annoy, alarm, inconvenience, upset, or embarrass another person;
                                        is calculated or is otherwise likely to deceive;
                                        is intended or otherwise likely to infringe (or threaten to infringe) another person’s right to privacy or otherwise uses their personal data in a way that you do not have a right to;
                                        misleadingly impersonates any person or otherwise misrepresents your identity or affiliation in a way that is calculated to deceive (obvious parodies are not included within this definition provided that they do not fall within any of the other provisions of this sub-Clause 7.2);
                                        implies any form of affiliation with Us where none exists;
                                        infringes, or assists in the infringement of, the intellectual property rights (including, but not limited to, copyright, patents, trademarks and database rights) of any other party; or
                                        is in breach of any legal duty owed to a third party including, but not limited to, contractual duties and duties of confidence.
                                        We reserve the right to suspend or terminate your Account and/or your access to Our Site if you materially breach the provisions of this Clause 7 or any of the other provisions of these Terms and Conditions. Specifically, We may take one or more of the following actions:
                                        suspend, whether temporarily or permanently, your Account and/or your right to access Our Site;
                                        remove any content submitted by you that violates this Acceptable Usage Policy;
                                        issue you with a written warning;
                                        take legal proceedings against you for reimbursement of any and all relevant costs on an indemnity basis resulting from your breach;
                                        take further legal action against you as appropriate;
                                        disclose such information to law enforcement authorities as required or as We deem reasonably necessary; and/or
                                        any other actions that We deem reasonably appropriate (and lawful).
                                        We hereby exclude any and all liability arising out of any actions (including, but not limited to those set out above) that We may take in response to breaches of these Terms and Conditions.</p>
                                </li>
                                <li>
                                    <h3>Disclaimers and Legal Rights</h3>
                                    <p>Nothing on Our Site constitutes advice on which you should rely. It is provided for general information purposes only. Professional or specialist advice should always be sought before taking any action relating to any healthcare advise.
                                        In so far as is permitted by law, We make no representation, warranty, or guarantee that Our Site will meet your requirements, that it will not infringe the rights of third parties, that it will be compatible with all software and hardware, or that it will be secure.
                                        If, as a result of Our failure to exercise reasonable care and skill, any digital content from Our Site damages your device or other digital content belonging to you, as a consumer you may be entitled to certain legal remedies. For more details concerning your rights and remedies as a consumer, please contact your local Citizens Advice Bureau or Trading Standards Office.
                                        We make reasonable efforts to ensure that Our Content on Our Site is complete, accurate, and up-to-date. We do not, however, make any representations, warranties, or guarantees (whether express or implied) that the Content is complete, accurate, or up-to-date.
                                        We are not responsible for the content or accuracy, or for any opinions, views, or values expressed in any Blogs, Posts, or Comments submitted by Users. Any such opinions, views, or values are those of the relevant User, and do not reflect Our opinions, views, or values in any way.</p>
                                </li>
                                <li>
                                    <h3>Our Liability</h3>
                                    <p>The provisions of this Clause 9 apply only to the use of Our Site and not to the sale of goods, which is governed separately by Our Terms of Sale.
                                        To the fullest extent permissible by law, We accept no liability to any User for any loss or damage, whether foreseeable or otherwise, in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising out of or in connection with the use of (or inability to use) Our Site or the use of or reliance upon any Content (whether that Content is provided by Us or contained in Blogs, Posts, or Comments created by Users) included on Our Site.
                                        To the fullest extent permissible by law, We exclude all representations, warranties, and guarantees (whether express or implied) that may apply to Our Site or any Content (including Blogs, Posts, or Comments created by Users) included on Our Site.
                                        If you are a business user, We accept no liability for loss of profits, sales, business or revenue; loss of business opportunity, goodwill or reputation; loss of anticipated savings; business interruption; or for any indirect or consequential loss or damage.
                                        We exercise all reasonable skill and care to ensure that Our Site is free from viruses and other malware, however, subject to sub-Clause 9.3, We accept no liability for any loss or damage resulting from a virus or other malware, a distributed denial of service attack, or other harmful material or event that may adversely affect your hardware, software, data or other material that occurs as a result of your use of Our Site (including the downloading of any content (including any provided in Blogs, Posts, or Comments) from it) or any other site referred to on Our Site.
                                        We neither assume nor accept responsibility or liability arising out of any disruption or non-availability of Our Site resulting from external causes including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, natural events, acts of war, or legal restrictions and censorship.
                                        Nothing in these Terms and Conditions excludes or restricts Our liability for fraud or fraudulent misrepresentation, for death or personal injury resulting from negligence, or for any other forms of liability which cannot be excluded or restricted by law. For full details of consumers’ legal rights, including those relating to digital content, please contact your local Citizens’ Advice Bureau or Trading Standards Office.</p>
                                </li>
                                <li>
                                    <h3>Viruses, Malware and Security</h3>
                                    <p>We exercise all reasonable skill and care to ensure that Our Site is secure and free from viruses and other malware including, but not limited to, the scanning of all Content uploaded to Our Site by Users for viruses and malware.
                                        You are responsible for protecting your hardware, software, data and other material from viruses, malware, and other internet security risks.
                                        You must not deliberately introduce viruses or other malware, or any other material which is malicious or technologically harmful either to or via Our Site.
                                        You must not attempt to gain unauthorised access to any part of Our Site, the server on which Our Site is stored, or any other server, computer, or database connected to Our Site.
                                        You must not attack Our Site by means of a denial of service attack, a distributed denial of service attack, or by any other means.
                                        By breaching the provisions of sub-Clauses 10.3 to 10.5 you may be committing a criminal offence under the Computer Misuse Act 1990. Any and all such breaches will be reported to the relevant law enforcement authorities and We will cooperate fully with those authorities by disclosing your identity to them. Your right to use Our Site will cease immediately in the event of such a breach and, where applicable, your Account will be suspended and/or deleted.</p>
                                </li>
                                <li>
                                    <h3>Communications from Us</h3>
                                    <p>If you have an Account, we may from time to time send you important notices by email. Such notices may relate to matters including, but not limited to, service changes, changes to these Terms and Conditions, and changes to your Account.
                                        We will never send you marketing emails of any kind without your express consent. If you do give such consent, you may opt out at any time. Any and all marketing emails sent by Us include an unsubscribe link. Email marketing options can also be changed in your Account preferences or unsubscribe from the marketing list from the marketing email received. If you opt out of receiving emails from Us at any time, it may take up to 48 hours for your new preferences to take effect.
                                        For questions or complaints about communications from Us (including, but not limited to marketing emails), please contact Us at <a href='https://cosmatherapy.com/contactus/'>Contact Us.</a></p>
                                </li>
                                <li>
                                    <h3>Changes to these Terms and Conditions</h3>
                                    <p>We may alter these Terms and Conditions including our Terms of Sale, Privacy & Cookies Policy at any time. [If We do so, details of the changes will be highlighted at the top of this page.] Any such changes will become binding on you upon your first use of Our Site after the changes have been implemented. You are therefore advised to check this page from time to time.
                                        In the event of any conflict between the current version of these Terms and Conditions and any previous version(s), the provisions current and in effect shall prevail unless it is expressly stated otherwise.</p>
                                </li>
                                <li>
                                    <h3>Law and Jurisdiction</h3>
                                    <p>These Terms and Conditions, and the relationship between you and Us (whether contractual or otherwise) shall be governed by and construed in accordance with the law of England & Wales.
                                        If you are a consumer, you will benefit from any mandatory provisions of the law in your country of residence. Nothing in Sub-Clause 13.1 above takes away or reduces your rights as a consumer to rely on those provisions.
                                        If you are a consumer, any dispute, controversy, proceedings or claim between you and Us relating to these Terms and Conditions, or the relationship between you and Us (whether contractual or otherwise) shall be subject to the jurisdiction of the courts of England, Wales, Scotland, or Northern Ireland, as determined by your residency.
                                        If you are a business, any disputes concerning these Terms and Conditions, the relationship between you and Us, or any matters arising therefrom or associated therewith (whether contractual or otherwise) shall be subject to the non- exclusive jurisdiction of the courts of England & Wales.</p>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div className='termsOfUseContent'>
                        <h1 className='pb-4'>Privacy & Cookies Policy</h1>
                        <p className='liPara'>BrainBerry Ltd understands that your privacy is important to you and that you care about how your personal data is used. We respect and value the privacy of everyone who visits this website, BrainBerry Ltd (“our Site”) and will only collect and use personal data in ways that are described here, and in a way that is consistent with our obligations and your rights under the law.

                            Please read this Privacy & Cookies Policy carefully and ensure that you understand it. Your acceptance of this Privacy & Cookies Policy is indicated by your use of Our Site.</p>
                        <ul className='joynithList'>
                            <li>
                                <h3>Definitions and Interpretation</h3>
                                <p>In this Policy the following terms shall have the following meanings:</p>
                            </li>
                            <li className='defination'>
                                <div className='row'>
                                    <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                        <div className='conWraper'>
                                            <p>“Account”</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-7 col-md-7 col-sm-7 col-7'>
                                        <div className='conWraper'>
                                            <p>means an account required for a User to access certain areas of Our Site, as detailed in Clause 4;</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className='defination'>
                                <div className='row'>
                                    <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                        <div className='conWraper'>
                                            <p>“Cookie”	</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-7 col-md-7 col-sm-7 col-7'>
                                        <div className='conWraper'>
                                            <p>means a small text file placed on your computer or device by our Site when you visit certain parts of our Site and/or when you use certain features of our Site.</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className='defination'>
                                <div className='row'>
                                    <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                                        <div className='conWraper'>
                                            <p>“Cookie Law”	</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-7 col-md-7 col-sm-7 col-7'>
                                        <div className='conWraper'>
                                            <p>means the relevant parts of the Privacy and Electronic Communications (EC Directive) Regulations 2003;</p>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className='pt-5'>
                                <h3>What Does This Policy Cover?</h3>
                                <p>This Privacy Policy applies only to your use ofour  Our Site may contain links to other websites. Please note that we have no control over how your data is collected, stored, or used by other websites and we advise you to check the privacy policies of any such websites before providing any data to them.</p>
                            </li>
                            <li>
                                <h3>What Is Personal Data?</h3>
                                <p>Personal data is defined by the General Data Protection Regulation (EU Regulation 2016/679) (the “GDPR”) and the Data Protection Act 2018 (collectively, “the Data Protection Legislation”) as ‘any information relating to an identifiable person who can be directly or indirectly identified in particular by reference to an identifier.
                                    Personal data is, in simpler terms, any information about you that enables you to be identified. Personal data covers obvious information such as your name and contact details, but it also covers less obvious information such as identification numbers, electronic location data, and other online identifiers.</p>
                            </li>
                            <li>
                                <h3>What Are My Rights?</h3>
                                <p>Under the Data Protection Legislation, you have the following rights, which we will always work to uphold:
                                    The right to be informed aboutour collection and use of your personal data. This Privacy Policy should tell you everything you need to know, but you can always contact us to find out more or to ask any questions.
                                    The right to access the personal datawe hold about you.
                                    The right to have your personal data rectified if any of your personal data held byus is inaccurate or incomplete.
                                    The right to be forgotten, i.e. the right to askus to delete or otherwise dispose of any of your personal data.
                                    The right to restrict (i.e. prevent) the processing of your personal data.
                                    The right to object tous using your personal data for a particular purpose or purposes.
                                    The right to withdraw consent. This means that, ifwe are relying on your consent as the legal basis for using your personal data, you are free to withdraw that consent at any time.
                                    The right to data portability. This means that, if you have provided personal data tous directly, we are using it with your consent or for the performance of a contract, and that data is processed using automated means, you can ask us for a copy of that personal data to re-use with another service or business in many cases.
                                    Rights relating to automated decision-making and profiling.
                                    For more information about our use of your personal data or exercising your rights as outlined above, please contact us. It is important that your personal data is kept accurate and up-to-date. If any of the personal data we hold about you changes, please keep us informed as long as we have that data.Further information about your rights can also be obtained from the Information Commissioner’s Office or your local Citizens Advice Bureau.
                                    If you have any cause for complaint aboutour use of your personal data, you have the right to lodge a complaint with the Information Commissioner’s Office. We would welcome the opportunity to resolve your concerns ourselves however, so please <a href="https://cosmatherapy.com/contactus/">contact us.</a></p>
                            </li>
                            <li>
                                <h3>Intellectual Property Rights</h3>
                                <p>With the exception of the content of Blogs and Posts (see Clause 7), all Content on Our Site and the copyright and other intellectual property rights subsisting in that Content, unless specifically labelled otherwise, belongs to or has been licensed by Us. All Content (including the content of Blogs and Posts) is protected by applicable United Kingdom and international intellectual property laws and treaties.
                                    Subject to the licence granted to Us under sub-Clause 7.4, Users retain the ownership of copyright and other intellectual property rights subsisting in the content of their Blogs, Posts and Comments (unless any part of that content is owned by a third party who has given their express permission for their material to be used).
                                    For personal use (including research and private study) only, you may:
                                    Access, view and use Our Site in a web browser (including any web browsing capability built into other types of software or app);
                                    Download Our Site (or any part of it) for caching;
                                    Print [one copy of any] page[s] from Our Site;
                                    Download, copy, clip, print, or otherwise save extracts from pages on Our Site;
                                    Save pages from Our Site for later and/or offline viewing; and
                                    View and use other Users’ Blogs, Posts and Comments in accordance with Clause
                                    You may not use any Content (including Blogs, Posts and Comments) downloaded, copied, clipped, printed or otherwise saved from Our Site for commercial purposes without first obtaining a licence to do so from Us, our licensors, or from the relevant User, as appropriate. [This does not prohibit the normal access, viewing and use of Our Site for general information purposes whether by business users or consumers].
                                    You may not systematically copy Content from Our Site with a view to creating or compiling any form of comprehensive collection, compilation, directory, or database unless given Our express permission to do so.
                                    Subject to sub-Clause[s] 6.3 [and 6.7] and Clause 7 (governing Blogs, Posts and Comments) you may not otherwise reproduce, copy, distribute, sell, rent, sub-licence, store, or in any other manner re-use Content or any other material from Our Site unless given express written permission to do so. For further information, please contact Us at Contact Us.
                                    Our status as the owner and author of the content on Our Site (or that of identified licensors or Users, as appropriate) must always be acknowledged.
                                    8 Nothing in these Terms and Conditions limits or excludes the fair dealing provisions of Chapter III of the Copyrights, Designs and Patents Act 1988 ‘Acts Permitted in Relation to Copyright Works’, covering in particular the making of temporary copies; research and private study; the making of copies for text and data analysis for non-commercial research; criticism, review, quotation and news reporting; caricature, parody or pastiche; and the incidental inclusion of copyright material.
                                    The content of Blogs, Posts, and Comments on Our Site and the copyright and other intellectual property rights subsisting therein, unless specifically labelled otherwise, belongs to or has been licensed by the User identified along with the Blog, Post, or Comment in question. All such content is protected by applicable United Kingdom and international intellectual property laws and treaties.
                                    You may copy and share (reblog) parts of other Users’ Posts and/or Comments within Our Site, provided that the original User is credited. If you wish to re-use another User’s content in any other way, you must contact the User directly and obtain their express permission to do so.
                                    Nothing in these Terms and Conditions limits or excludes the fair dealing provisions of Chapter III of the Copyright, Designs and Patents Act 1988 ‘Acts Permitted in Relation to Copyright Works’, covering in particular the making of temporary copies; research and private study; the making of copies for text and data analysis for non-commercial research; criticism, review, quotation and news reporting; caricature, parody or pastiche; and the incidental inclusion of copyright material.</p>
                            </li>
                            <li>
                                <h3>Acceptable Usage Policy</h3>
                                <p>You may only use Our Site (including, but not limited to, the creation of Blogs, Posts, and Comments) in a manner that is lawful. Specifically:
                                    you must ensure that you comply fully with any and all applicable local, national and international laws and/or regulations;
                                    you must not use Our Site in any way, or for any purpose, that is unlawful or fraudulent;
                                    you must not use Our Site to knowingly send, upload, or in any other way transmit data that contains any form of virus or other malware, or any other code designed to adversely affect computer hardware, software, or data of any kind; and
                                    you must not use Our Site in any way, or for any purpose, that is intended to harm any person or persons in any way.
                                    When creating Blogs, Posts, or Comments and When submitting Posts (or communicating in any other way using Our Site), you agree that you will be solely responsible for your Blog(s) and Posts and for any Comments you make anywhere on our Site and you must not submit, communicate or otherwise do anything that:
                                    you must not post, communicate, or otherwise do anything that:
                                    is sexually explicit;
                                    is obscene, deliberately offensive, hateful or otherwise inflammatory;
                                    promotes violence;
                                    promotes or assists in any form of unlawful activity; discriminates against, or is in any way defamatory of, any person, group or class of persons, race, sex, religion, nationality, disability, sexual orientation or age;
                                    is intended or otherwise likely to threaten, harass, annoy, alarm, inconvenience, upset, or embarrass another person;
                                    is calculated or is otherwise likely to deceive;
                                    is intended or otherwise likely to infringe (or threaten to infringe) another person’s right to privacy or otherwise uses their personal data in a way that you do not have a right to;
                                    misleadingly impersonates any person or otherwise misrepresents your identity or affiliation in a way that is calculated to deceive (obvious parodies are not included within this definition provided that they do not fall within any of the other provisions of this sub-Clause 7.2);
                                    implies any form of affiliation with Us where none exists;
                                    infringes, or assists in the infringement of, the intellectual property rights (including, but not limited to, copyright, patents, trademarks and database rights) of any other party; or
                                    is in breach of any legal duty owed to a third party including, but not limited to, contractual duties and duties of confidence.
                                    We reserve the right to suspend or terminate your Account and/or your access to Our Site if you materially breach the provisions of this Clause 7 or any of the other provisions of these Terms and Conditions. Specifically, We may take one or more of the following actions:
                                    suspend, whether temporarily or permanently, your Account and/or your right to access Our Site;
                                    remove any content submitted by you that violates this Acceptable Usage Policy;
                                    issue you with a written warning;
                                    take legal proceedings against you for reimbursement of any and all relevant costs on an indemnity basis resulting from your breach;
                                    take further legal action against you as appropriate;
                                    disclose such information to law enforcement authorities as required or as We deem reasonably necessary; and/or
                                    any other actions that We deem reasonably appropriate (and lawful).
                                    We hereby exclude any and all liability arising out of any actions (including, but not limited to those set out above) that We may take in response to breaches of these Terms and Conditions.</p>
                            </li>
                            <li>
                                <h3>Disclaimers and Legal Rights</h3>
                                <p>Nothing on Our Site constitutes advice on which you should rely. It is provided for general information purposes only. Professional or specialist advice should always be sought before taking any action relating to any healthcare advise.
                                    In so far as is permitted by law, We make no representation, warranty, or guarantee that Our Site will meet your requirements, that it will not infringe the rights of third parties, that it will be compatible with all software and hardware, or that it will be secure.
                                    If, as a result of Our failure to exercise reasonable care and skill, any digital content from Our Site damages your device or other digital content belonging to you, as a consumer you may be entitled to certain legal remedies. For more details concerning your rights and remedies as a consumer, please contact your local Citizens Advice Bureau or Trading Standards Office.
                                    We make reasonable efforts to ensure that Our Content on Our Site is complete, accurate, and up-to-date. We do not, however, make any representations, warranties, or guarantees (whether express or implied) that the Content is complete, accurate, or up-to-date.
                                    We are not responsible for the content or accuracy, or for any opinions, views, or values expressed in any Blogs, Posts, or Comments submitted by Users. Any such opinions, views, or values are those of the relevant User, and do not reflect Our opinions, views, or values in any way.</p>
                            </li>
                            <li>
                                <h3>Our Liability</h3>
                                <p>The provisions of this Clause 9 apply only to the use of Our Site and not to the sale of goods, which is governed separately by Our Terms of Sale.
                                    To the fullest extent permissible by law, We accept no liability to any User for any loss or damage, whether foreseeable or otherwise, in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising out of or in connection with the use of (or inability to use) Our Site or the use of or reliance upon any Content (whether that Content is provided by Us or contained in Blogs, Posts, or Comments created by Users) included on Our Site.
                                    To the fullest extent permissible by law, We exclude all representations, warranties, and guarantees (whether express or implied) that may apply to Our Site or any Content (including Blogs, Posts, or Comments created by Users) included on Our Site.
                                    If you are a business user, We accept no liability for loss of profits, sales, business or revenue; loss of business opportunity, goodwill or reputation; loss of anticipated savings; business interruption; or for any indirect or consequential loss or damage.
                                    We exercise all reasonable skill and care to ensure that Our Site is free from viruses and other malware, however, subject to sub-Clause 9.3, We accept no liability for any loss or damage resulting from a virus or other malware, a distributed denial of service attack, or other harmful material or event that may adversely affect your hardware, software, data or other material that occurs as a result of your use of Our Site (including the downloading of any content (including any provided in Blogs, Posts, or Comments) from it) or any other site referred to on Our Site.
                                    We neither assume nor accept responsibility or liability arising out of any disruption or non-availability of Our Site resulting from external causes including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, natural events, acts of war, or legal restrictions and censorship.
                                    Nothing in these Terms and Conditions excludes or restricts Our liability for fraud or fraudulent misrepresentation, for death or personal injury resulting from negligence, or for any other forms of liability which cannot be excluded or restricted by law. For full details of consumers’ legal rights, including those relating to digital content, please contact your local Citizens’ Advice Bureau or Trading Standards Office.</p>
                            </li>
                            <li>
                                <h3>Viruses, Malware and Security</h3>
                                <p>We exercise all reasonable skill and care to ensure that Our Site is secure and free from viruses and other malware including, but not limited to, the scanning of all Content uploaded to Our Site by Users for viruses and malware.
                                    You are responsible for protecting your hardware, software, data and other material from viruses, malware, and other internet security risks.
                                    You must not deliberately introduce viruses or other malware, or any other material which is malicious or technologically harmful either to or via Our Site.
                                    You must not attempt to gain unauthorised access to any part of Our Site, the server on which Our Site is stored, or any other server, computer, or database connected to Our Site.
                                    You must not attack Our Site by means of a denial of service attack, a distributed denial of service attack, or by any other means.
                                    By breaching the provisions of sub-Clauses 10.3 to 10.5 you may be committing a criminal offence under the Computer Misuse Act 1990. Any and all such breaches will be reported to the relevant law enforcement authorities and We will cooperate fully with those authorities by disclosing your identity to them. Your right to use Our Site will cease immediately in the event of such a breach and, where applicable, your Account will be suspended and/or deleted.</p>
                            </li>
                            <li>
                                <h3>Communications from Us</h3>
                                <p>If you have an Account, we may from time to time send you important notices by email. Such notices may relate to matters including, but not limited to, service changes, changes to these Terms and Conditions, and changes to your Account.
                                    We will never send you marketing emails of any kind without your express consent. If you do give such consent, you may opt out at any time. Any and all marketing emails sent by Us include an unsubscribe link. Email marketing options can also be changed in your Account preferences or unsubscribe from the marketing list from the marketing email received. If you opt out of receiving emails from Us at any time, it may take up to 48 hours for your new preferences to take effect.
                                    For questions or complaints about communications from Us (including, but not limited to marketing emails), please contact Us at <a href='https://cosmatherapy.com/contactus/'>Contact Us.</a></p>
                            </li>
                            <li>
                                <h3>What Data is collected & used and where is it stored?</h3>
                                <p>Depending upon your use of our Site, we may collect and hold some or all of the personal and non-personal data set out in the table below, using the methods also set out in the table. we do not collect any personal data relating to children and data relating to criminal convictions and/or offences.
                                    The <a href='https://cosmatherapy.com/site-cookies/'>following link</a> describes how we may use your personal data, and our lawful bases for doing so.
                                    With your permission and/or where permitted by law, we may also use your personal data for marketing purposes, which may include contacting you by either email, telephone, text message or post with information, news, and offers on our products and services. You will not be sent any unlawful marketing or spam. We will always work to fully protect your rights and comply with our obligations under the Data Protection Legislation and the Privacy and Electronic Communications (EC Directive) Regulations 2003, and you will always have the opportunity to opt-out. we will always obtain your express opt-in consent before sharing your personal data with third parties for marketing purposes and you will be able to opt-out at any time.
                                    We use the following automated system[s] for carrying out certain kinds of [decision-making AND/OR [profiling]. If at any point you wish to query any action that we take on the basis of this or wish to request ‘human intervention’ (i.e. have someone review the action themselves, rather than relying only on the automated method), the Data Protection Legislation gives you the right to do so. Please contact us.
                                    The following automated decision-making method(s) may be used:
                                    automated decision-making may be made for understanding the user likeness of the website and products to change the services in order to provide personalised service to the provider with their consent to collect the data required.
                                    The following automated profiling may take place:
                                    To analyse the dementia patient group for further research purposes and development of new products and service. The profiling will be also cared on to provide personalised product to a user as per the subscription requirement. The personal data collected from cosmaonline.com is collected only after the user provides consent to the form.
                                    We will only use your personal data for the purpose(s) for which it was originally collected unless we reasonably believe that another purpose is compatible with that or those original purpose(s) and need to use your personal data for that purpose. If we do use your personal data in this way and you wish us to explain how the new purpose is compatible with the original, please contact us using the details in clause 11 of End User Agreement.
                                    If we need to use your personal data for a purpose that is unrelated to, or incompatible with, the purpose(s) for which it was originally collected, we will inform you and explain the legal basis which allows us to do so.
                                    In some circumstances, where permitted or required by law, we may process your personal data without your knowledge or consent. This will only be done within the bounds of the Data Protection Legislation and your legal rights. We will not keep your personal data for any longer than is necessary in light of the reason(s) for which it was first collected.
                                    We may store or transfer some or all of your personal data in countries that are not part of the European Economic Area (the “EEA” consists of all EU member states, plus Norway, Iceland, and Liechtenstein). These are known as “third countries” and may not have data protection laws that are as strong as those in the UK and/or the EEA. This means that we will take additional steps in order to ensure that your personal data is treated just as safely and securely as it would be within the UK and under the Data Protection Legislation as follows:
                                    we share your data within the group of companies of which we are a part. Where this involves the transfer of personal data outside the EEA, our group ensures that personal data is protected by requiring all companies within the group to follow the same rules with respect to personal data usage. These are known as “binding corporate rules”. More information on binding corporate rules is available from the European Commission.
                                    [AND] We may use specific contracts with external third parties that are approved by the European Commission for the transfer of personal data to third countries. These contracts require the same levels of personal data protection that would apply under the Data Protection Legislation. More information is available from the European Commission.
                                    The security of your personal data is essential to us, and to protect your data, we take a number of important measures, including the following:
                                    limiting access to your personal data to those employees, agents, contractors, and other third parties with a legitimate need to know and ensuring that they are subject to duties of confidentiality;
                                    procedures for dealing with data breaches (the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, your personal data) including notifying you and/or the Information Commissioner’s Office wherewe are legally required to do so;</p>
                            </li>
                            <li>
                                <h3>Do You Share My Personal Data?</h3>
                                <p>We may share your personal data with other companies in our group for support and IT Maintenance. This includes our exclusive subcontractor who provide exclusive support to BrainBerry Ltd. we may sometimes contract with the following third parties to supply certain products and </p>
                            </li>
                            <li>
                                <h3>Recipient</h3>
                                <p>DataBase & Server Maintenance</p>
                            </li>
                            <li>
                                <h3>Activity Carried Out	</h3>
                                <p>Server & Database Administration and encrypted Security Maintenance.</p>
                            </li>
                            <li>
                                <h3>Sector	</h3>
                                <p>Support Service	</p>
                            </li>
                            <li>
                                <h3>Location</h3>
                                <p>India.</p>
                                <p>If any of your personal data is shared with a third party, as described above,we will take steps to ensure that your personal data is handled safely, securely, and in accordance with your rights, our obligations, and the third party’s obligations under the law, as described above in Part 9.
                                    If any personal data is transferred outside of the EEA,we will take suitable steps in order to ensure that your personal data is treated just as safely and securely as it would be within the UK and under the Data Protection Legislation, as explained above in Part 9.
                                    If we sell, transfer, or merge parts of our business or assets, your personal data may be transferred to a third party. Any new owner of our business may continue to use your personal data in the same way(s) that we have used it, as specified in this Privacy Policy.
                                    In some limited circumstances,we may be legally required to share certain personal data, which might include yours, if we are involved in legal proceedings or complying with legal obligations, a court order, or the instructions of a government authority.</p>
                            </li>
                            <li>
                                <h3>How Can I Control My Personal Data?</h3>
                                <p>In addition to your rights under the Data Protection Legislation, set out in Part 5, when you submit personal data viaour Site, you may be given options to restrict our use of your personal data. In particular, we aim to give you strong controls on our use of your data for direct marketing purposes (including the ability to opt-out of receiving emails from us which you may do by unsubscribing using the links provided in our emails and at the point of providing your details and by managing your Account.
                                    You may also wish to sign up to one or more of the preference services operating in the UK: The Telephone Preference Service (“the TPS”), the Corporate Telephone Preference Service (“the CTPS”), and the Mailing Preference Service (“the MPS”). These may help to prevent you receiving unsolicited marketing. Please note, however, that these services will not prevent you from receiving marketing communications that you have consented to receiving.
                                </p>
                            </li>
                            <li>
                                <h3>Can I Withhold Information?</h3>
                                <p>You may access certain areas of our Site without providing any personal data at all. However, to use all features and functions available on our Site you may be required to submit or allow for the collection of certain data. You may restrict our use of Cookies.
                                </p>
                            </li>
                            <li>
                                <h3>How Can I Access My Personal Data?</h3>
                                <p>If you want to know what personal data, we have about you, you can ask us for details of that personal data and for a copy of it (where any such personal data is held). This is known as a “subject access request”.
                                    All subject access requests should be made in writing and sent to the email or postal addresses shown in clause 11 of End User Agreement.
                                    There is not normally any charge for a subject access request. If your request is ‘manifestly unfounded or excessive’ (for example, if you make repetitive requests) a fee may be charged to cover our administrative costs in responding.
                                    We will respond to your subject access request within 30 days of receiving it. Normally, we aim to provide a complete response, including a copy of your personal data within that time. In some cases, however, particularly if your request is more complex, more time may be required up to a maximum of three months from the date we receive your request.

                                </p>
                            </li>
                            <li>
                                <h3>How Does Our Site Use Cookies?</h3>
                                <p>Our Site may place and access certain first party and third party Cookies on your computer or device. First party Cookies are those placed directly by Us and are used only by Us. Third party Cookies are those placed by websites, services, and/or parties other than Us. We use Cookies to facilitate and improve your experience of Our Site and to provide and improve Our [products]AND/OR [services]. We have carefully chosen these Cookies and have taken steps to ensure that your privacy and personal data is protected and respected at all times.
                                    All Cookies used by and on Our Site are used in accordance with current Cookie Law. Click Here to find the Cookies used in our site and their functions.

                                </p>
                            </li>
                            <li>
                                <h3>Consent and Control</h3>
                                <p>Before Cookies are placed on your computer or device, you will be shown a banner at the bottom of the homepage of our site requesting your consent to set those Cookies. By giving your consent to the placing of Cookies you are enabling Us to provide the best possible experience and service to you. You may, if you wish, deny consent to the placing of Cookies unless those Cookies are strictly necessary; however certain features of Our Site may not function fully or as intended. You will be given the opportunity to allow and/or deny different categories of Cookie that We use. You can return to your Cookie preferences to review and/or change them at any time by allowing preferences from the banner at the bottom of our site.
                                    In addition to the controls that We provide, you can choose to enable or disable Cookies in your internet browser. Most internet browsers also enable you to choose whether you wish to disable all Cookies or only third party Cookies. By default, most internet browsers accept Cookies but this can be changed. For further details, please consult the help menu in your internet browser or the documentation that came with your device.
                                    The links below provide instructions on how to control Cookies in all mainstream browsers:
                                    Google Chrome: https://support.google.com/chrome/answer/95647?hl=en-GB
                                    Microsoft Internet Explorer: https://support.microsoft.com/en-us/kb/278835
                                    Microsoft Edge: https://support.microsoft.com/en-gb/products/microsoft-edge(Please note that there are no specific instructions at this time, but Microsoft support will be able to assist)
                                    Safari (macOS): https://support.apple.com/kb/PH21411?viewlocale=en_GB&locale=en_GB
                                    Safari (iOS): https://support.apple.com/en-gb/HT201265
                                    Mozilla Firefox: https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences
                                    Android: https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en(Please refer to your device’s documentation for manufacturers’ own browsers).

                                </p>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
            <section className="py-9 subscribeWwrap">
                <div class="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div>
                                <img src="/Images/mail.png" alt="ww" />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="contenWraper mt-4 text-center">
                                <h3 className="subsTitle">Subscribe</h3>
                                <p className="parte">Please enter your email and subscribe our newsletter, for updates.</p>
                                <div>
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                                                <input className="subscribeInput" placeholder="Enter your Email" type="text" />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-4 ps-0">
                                                <button type="button" className="Subscribe">Subscribe</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}