import * as AccountAction from '../actions/types';

const initialState={
    data:'',
}

const AccountReducer=(state=initialState, action)=>{
    switch(action.type){
        case AccountAction.SIGNIN_USER:
            return{
                ...state,
                data:action.payload
        }
        default:return state;
    }
}

export default AccountReducer;