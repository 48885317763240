// import { IoBarChartOutline,IoPersonAddOutline,IoHeartOutline,IoGiftOutline } from "react-icons/io5";
import './features.css'
import Loading from '../ContentLoading';

export default function WhatDone(props) {
    const data = props.dataFeaturs
    if (!data) { return <Loading /> }
    const { hero_overview_section, hero_overview_section_bg_image } = data[0];

    return (
        <div className='DoneWrap' style={{ backgroundImage: `url(${hero_overview_section_bg_image.url})` }}>
            <div className='container'>
                <h1 className='brainTitle zIndex text-light'>What we done</h1>
                <div className='row'>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-12 mb-3'>
                        <div className='DonecontentWrap'>
                            <div className='imageWrap'>
                                {/* <IoBarChartOutline className="oneIcon" /> */}
                            </div>
                            <div className='content'>
                                <h2> {hero_overview_section.projects_completed}+ </h2>
                                <p>{hero_overview_section.title_one}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-12 mb-3'>
                        <div className='DonecontentWrap'>
                            <div className='imageWrap'>
                                {/* <IoPersonAddOutline className="TwoIcon" /> */}
                            </div>
                            <div className='content'>
                                <h2>  {hero_overview_section.satisfied_clients}+  </h2>
                                <p>{hero_overview_section.title_two}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-12 mb-3'>
                        <div className='DonecontentWrap'>
                            <div className='imageWrap'>
                                {/* <IoHeartOutline className="threeIcon" /> */}
                            </div>
                            <div className='content'>
                                <h2> {hero_overview_section.positive_feedbacks}+  </h2>
                                <p>{hero_overview_section.title_three}</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-6 col-12 mb-3'>
                        <div className='DonecontentWrap'>
                            <div className='imageWrap'>
                                {/* <IoGiftOutline className="fourIcon" /> */}
                            </div>
                            <div className='content'>
                                <h2>{hero_overview_section.freebies_released}+  </h2>
                                <p>{hero_overview_section.title_four}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-center zIndex mt-5'>
                    <a href="#brain" className='BtnDemo' >Learn More</a>
                </div>
            </div>
        </div>
    )
}