import './features.css';
import Loading from '../ContentLoading';


export default function Process(props) {
    const dataFe = props.dataFeaturs
    if(!dataFe){return <Loading/>}

    const {unique_features_of_cosma} = dataFe[0];

    const {
        section_one_title,
        section_one_description,
        section_one_image,
        section_two_title,
        section_two_description,
        section_two_image,
        section_three_title,
        section_three_description,
        section_three_image,
        section_four_title,
        section_four_description,
        section_four_image} = unique_features_of_cosma;

    console.log(section_one_title)
    return (
        <div className='processWrap'>
            <div className='container'>
                <h1 className='brainTitle'>{unique_features_of_cosma.section_heading}</h1>
                <div className='processList'>
                    <div className='processItem'>
                        <div className='row'>
                            <div className='col-lg-7 col-md-7 col-sm-6 col-12'>
                                <div className='processItemContent RightOne'>
                                    <h1 className='num'>01</h1>
                                    <h1>{section_one_title}</h1>
                                    <p>
                                        {
                                           section_one_description
                                        }
                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-5 col-sm-6 col-12'>
                                <div className='processItemImage'>
                                    <img src={section_one_image.url} alt="tets" />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className='processItem'>
                        <div className='row'>
                            <div className='col-lg-5 col-md-5 col-sm-6 col-12'>
                                <div className='processItemImage'>
                                    <img src={section_two_image.url} alt="tets" />
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-7 col-sm-6 col-12'>
                                <div className='processItemContent LeftOne'>
                                    <h1 className='num'>02</h1>
                                    <h1> {section_two_title} </h1>
                                    <p>


                                    {
                                            section_two_description
                                        }

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='processItem'>
                        <div className='row'>
                            <div className='col-lg-7 col-md-7 col-sm-6 col-12'>
                                <div className='processItemContent RightOne'>
                                    <h1 className='num'>03</h1>
                                    <h1> {section_three_title}</h1>
                                    <p>

                                    {
                                            section_three_description
                                        }

                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-5 col-md-5 col-sm-6 col-12'>
                                <div className='processItemImage'>
                                    <img src={section_three_image.url} alt="tets" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='processItem'>
                        <div className='row'>
                            <div className='col-lg-5 col-md-5 col-sm-6 col-12'>
                                <div className='processItemImage'>
                                    <img src={section_four_image.url} alt="tets" />
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-7 col-sm-6 col-12'>
                                <div className='processItemContent LeftOne'>
                                    <h1 className='num'>04</h1>
                                    <h1> {section_four_title} </h1>
                                    <p>
                                    {
                                            section_four_description
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}