import React from 'react'
import Iframe from 'react-iframe'

export default function bookdemo() {
    return (
        <div className='bookWraoer'>
            <Iframe src='https://crm.zoho.eu/bookings/COSMAProductDemo?rid=e7ba3b9984a25bb106550d253b5cf9fc1dd95e7d2aa1105dd46140a15b60340fgidbe83daab532ec45596b0a2924337a7ce456a7e785465d99f83b0859abd1c78eb&option=embed'
            width="100%"
            height="1000px"
            />
        </div>
    )
}
