
import * as UploadedAction from '../../Types/UploadedUITypes';
import axios from 'axios';

const baseUrl =  "https://test.cosmaonline.com/cosma/api";
const token = localStorage.getItem("access_token");

export const fetchSongs=()=>{
    return function(dispatch){
        axios.get(baseUrl + '/SongUi/GetSongs', {
                headers: {
                    'Authorization': 'bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }
        )
        .then(res=>
            {
                const songs = res.data;
                dispatch({
                  type:UploadedAction.SONGS_LIST,
                  payload:songs
                 });
            })
        .catch(err=>console.log(err));  
    }
}

export const GETSongById = (Id) => {
  return function (dispatch) {
    axios
      .get(baseUrl + "/SongUi/GetSongById?Id=" + Id, {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        const song = response.data;
        dispatch({
          type: UploadedAction.SONGS_EDIT,
          payload: song,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const CREATESong = (songs) => {
  axios
    .post(baseUrl + "​​/SongUi/Save", songs, {
      headers: {
        Authorization: "bearer " + token,
        "content-type": "application/json",
      },
    })
    .then(function () {
      return {
        type: UploadedAction.SONGS_ADD,
        payload: songs,
      };
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const deleteSong = (id) => {
  axios
    .post(baseUrl + "/api/SongUi/Delete?Id=" + id, {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => console.log(res))
    .catch((err) => console.log(err));
  return {
    type: UploadedAction.SONGS_DELETE,
    payload: id,
  };
};
