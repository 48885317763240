// import { Link } from 'react-router-dom'
import React, { useState, useEffect } from "react";
import './subscribePage.css';

export default function Pricing(props) {

    const Price = props.dataPricing;
    const dataPricingOfferOne = props.dataPricingOfferOne;
    const dataPricingOfferThree = props.dataPricingOfferThree;
    const dataPricingOfferTwo = props.dataPricingOfferTwo;

    if (!Price) { return null };
    if (!dataPricingOfferOne) { return null };
    if (!dataPricingOfferTwo) { return null };
    if (!dataPricingOfferThree) { return null };



    return (

        <div className='container'>
            <div className="pricingWrap">
                <div className='pricingContent'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-5 col-12'>
                            <div>
                                <ul className="nav nav-tabs nav-justified mb-5 singlePay" id="ex1" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a
                                            className="nav-link active"
                                            id="ex3-tab-2"
                                            data-mdb-toggle="tab"
                                            href="#ex3-tabs-2"
                                            role="tab"
                                            aria-controls="ex3-tabs-2"
                                            aria-selected="true"
                                        >Monthly</a>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <a
                                            className="nav-link"
                                            id="ex3-tab-4"
                                            data-mdb-toggle="tab"
                                            href="#ex3-tabs-4"
                                            role="tab"
                                            aria-controls="ex3-tabs-4"
                                            aria-selected="false"
                                        >Annually</a
                                        >
                                    </li>
                                    <div className='singlePayBorder'></div>
                                </ul>
                                <div className="tab-content" id="ex2-content">
                                    <div
                                        className="tab-pane fade show active"
                                        id="ex3-tabs-2"
                                        role="tabpanel"
                                        aria-labelledby="ex3-tab-2">
                                        <div className="pricing-table">

                                            <div className="card singleSub">

                                                <h6 className="type">{Price[2].title.rendered}</h6>
                                                <div class="pricePage"><span>£</span>{Price[2].acf.monthly}<span>/user</span></div>

                                                <ul className="details">
                                                    {dataPricingOfferOne.map(item => (
                                                        <li key={item.id}>{item.name}</li>
                                                    ))}
                                                    <li><input type="hidden" value={Price[2].acf.monthly} /></li>
                                                </ul>
                                                 <a href="https://buy.stripe.com/6oE5mdgk05Xn0es5kr"><div className="buy-button singleBuyBtn">
                                                    <h3 className="btn">subscribe</h3> 
                                                </div></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="ex3-tabs-4"
                                        role="tabpanel"
                                        aria-labelledby="ex3-tab-4">
                                        <div
                                            className="tab-pane fade show"
                                            id="ex3-tabs-2"
                                            role="tabpanel"
                                            aria-labelledby="ex3-tab-2">
                                            <div className="pricing-table">

                                                <div className="card singleSub">

                                                    <h6 className="type">{Price[2].title.rendered}</h6>
                                                    <div class="pricePage"><span>£</span>{Price[2].acf.annually}<span>/user</span></div>
                                                    
                                                    <ul className="details">
                                                        {dataPricingOfferOne.map(item => (
                                                            <li key={item.id}>{item.name}</li>
                                                        ))}
                                                        <li><input type="hidden" value={Price[2].acf.annually} /></li>
                                                    </ul>
                                                    <a href="https://buy.stripe.com/fZecOFaZGdpP3qE7st"><div className="buy-button singleBuyBtn">
                                                        <h3 className="btn">subscribe</h3>
                                                    </div></a>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='col-lg-8 col-md-8 col-sm-7 col-12'>
                            <div>
                                <ul className="nav nav-tabs nav-justified mb-5 m-auto" id="ex1" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a
                                            className="nav-link active"
                                            id="ex3-tab-1"
                                            data-mdb-toggle="tab"
                                            href="#ex3-tabs-1"
                                            role="tab"
                                            aria-controls="ex3-tabs-1"
                                            aria-selected="true"
                                        >Quarterly</a>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <a
                                            className="nav-link"
                                            id="ex3-tab-3"
                                            data-mdb-toggle="tab"
                                            href="#ex3-tabs-3"
                                            role="tab"
                                            aria-controls="ex3-tabs-3"
                                            aria-selected="false"
                                        >Annually</a
                                        >
                                    </li>
                                    <div className='singlePayBorder'></div>
                                </ul>

                                <div className="tab-content" id="ex2-content">
                                    <div
                                        className="tab-pane fade show active"
                                        id="ex3-tabs-1"
                                        role="tabpanel"
                                        aria-labelledby="ex3-tab-1"
                                    >


                                        <div className="pricing-table">

                                            <div className="card ndCard">
                                                <h6 className="type">{Price[1].title.rendered}</h6>
                                                <div class="pricePage"><span>£</span>{Price[1].acf.quarterly}<span>/user</span></div>
                                                
                                                <ul className="details">
                                                    {dataPricingOfferTwo.map(item => (
                                                        <li key={item.id}>{item.name}</li>
                                                    ))}
                                                </ul>
                                                <a href="https://buy.stripe.com/00g8yp2tagC19P23cf"><div className="buy-button">
                                                <h3 className="btn">subscribe</h3>
                                                </div></a>
                                            </div>

                                            <div className="card">
                                                <h6 className="type">{Price[0].title.rendered}</h6>
                                                <div class="pricePage"><span>£</span>{Price[0].acf.quarterly}<span>/user</span></div>
                                                
                                                
                                                <ul className="details">
                                                    {dataPricingOfferThree.map(item => (
                                                        <li key={item.id}>{item.name}</li>
                                                    ))}
                                                </ul>
                                                
                                                <a href="https://buy.stripe.com/fZeaGxgk01H73qE146"><div className="buy-button">
                                               <h3 className="btn">subscribe</h3>
                                                </div></a>
                                            </div>

                                        </div>

                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="ex3-tabs-3"
                                        role="tabpanel"
                                        aria-labelledby="ex3-tab-3"
                                    >
                                        <div
                                            className="tab-pane fade show active"
                                            id="ex3-tabs-1"
                                            role="tabpanel"
                                            aria-labelledby="ex3-tab-1"
                                        >


                                            <div className="pricing-table">

                                                <div className="card ndCard">
                                                    <h6 className="type">{Price[1].title.rendered}</h6>
                                                    <div class="pricePage"><span>£</span>{Price[1].acf.annually}<span>/user</span></div>
                                                   
                                                    <ul className="details">
                                                        {dataPricingOfferTwo.map(item => (
                                                            <li key={item.id}>{item.name}</li>
                                                        ))}
                                                    </ul>
                                                    <a href="https://buy.stripe.com/6oEeWNffWetT0es7sw"><div className="buy-button">
                                                    <h3 className="btn">subscribe</h3>
                                                    </div></a>
                                                </div>

                                                <div className="card">
                                                    <h6 className="type">{Price[0].title.rendered}</h6>
                                                    <div class="pricePage"><span>£</span>{Price[0].acf.annually}<span>/user</span></div>
                                                    
                                                    <ul className="details">
                                                        {dataPricingOfferThree.map(item => (
                                                            <li key={item.id}>{item.name}</li>
                                                        ))}
                                                    </ul>
                                                    <a href="https://buy.stripe.com/3csaGxffW5Xn2mA005"> <div className="buy-button">
                                                   <h3 className="btn">subscribe</h3>
                                                    </div></a>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}