import * as UploadedAction from '../../Types/UploadedUITypes';

const initialState={
    schedule:'',
    schedules:[],
    id:'',
}

const ScheduleReducer=(state=initialState, action)=>{

    switch(action.type){
        case UploadedAction.SCHEDULE_LIST:return{
            ...state,
            schedules:action.payload
        }
        case UploadedAction.SCHEDULE_ADD:return{
            ...state,
            schedule:action.payload
        }
        case UploadedAction.SCHEDULE_EDIT:return{
            ...state,
            schedule:action.payload,          
        }
        case UploadedAction.SCHEDULE_DELETE:return{
            ...state
        }
        default:return state;
    }
}

export default ScheduleReducer;