import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//import DialogContentText from '@material-ui/core/DialogContentText';
import CardHeader from "../../../components/Card/CardHeader.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from "@material-ui/core";
import ImageUpload from "../../../components/CustomUpload/ImageUpload";
import { useNavigate  } from "react-router";
import { CREATEItems } from "../../../actions/UploadedUI/ItemsAction";
import { useDispatch } from "react-redux";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
// import ImageUploadCard from "components/UploadImage/ImageUploadCard";

// const galleryImageList = [
//     "https://raw.githubusercontent.com/dxyang/StyleTransfer/master/style_imgs/mosaic.jpg",
//     "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg/1280px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg",
//     "https://raw.githubusercontent.com/ShafeenTejani/fast-style-transfer/master/examples/dora-maar-picasso.jpg",
//     "https://pbs.twimg.com/profile_images/925531519858257920/IyYLHp-u_400x400.jpg",
//     "https://raw.githubusercontent.com/ShafeenTejani/fast-style-transfer/master/examples/dog.jpg",
//     "http://r.ddmcdn.com/s_f/o_1/cx_462/cy_245/cw_1349/ch_1349/w_720/APL/uploads/2015/06/caturday-shutterstock_149320799.jpg"
//   ];

const styles = {
  rightText: {
    display: "inline-block",
    float: "right",
    color: "#FFFFFF"
  },
  textMargin: {
    marginTop: "10px"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      textAlign: "right",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const InitialState = {
  id: 0,
  name: "",
  photo: "",
  colourId: 0,
  userId: 0
};

const useStyles = makeStyles(styles);

export default function CreateModal() {
  const [open, SetOpen] = useState(false);
  const [item, SetItems] = useState(InitialState);
  const [error, SetError] = useState();
  const history = useNavigate ();
  const classes = useStyles();
  // const organization =useSelector(state=>state.Org.organization.id);
  // console.log(organization);
  const dispatch = useDispatch();
  // useEffect(()=>{
  //   // dispatch(CREATEOrganization(org));
  // },[]);

  const handleClickOpen = () => {
    SetOpen(true);
  };
  const { name, colorId, photo } = item;
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    SetItems({ ...item, [name]: value });
  };
  const formSubmitHandler = (e) => {
    // e.preventDefault;

    if (!name) {
      SetError("Please input All Fields");
    } else {
      dispatch(CREATEItems(item));
      history.push("/admin/ItemsUI");
      SetOpen(false);
    }
  };
  const handleClose = () => {
    SetOpen(false);
  };

  return (
    <div>
      <Button
        className={classes.rightText}
        variant="outlined"
        onClick={handleClickOpen}
      >
        Add New
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="Add-Items"
        className={classes.cardTitleWhite}
      >
        <CardHeader color="primary">
          <DialogTitle id="Add-Items" className={classes.textMargin}>
            {" "}
            Add Item
          </DialogTitle>
        </CardHeader>{" "}
        <form onSubmit={formSubmitHandler}>
          <GridContainer>
            <DialogContent xs={8} sm={8} md={8}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {error && <h5 style={{ color: "red" }}>{error}</h5>}
                </GridItem>
              </GridContainer>
              <TextField
                id="UserId"
                name="userId"
                value={localStorage.getItem("userId")}
                hidden
              />
              <ImageUpload
                style={{ textAlign: "center" }}
                id="photo"
                name="photo"
                value={photo}
                onChange={handleInputChange}
              />
              {/* <ImageUploadCard cardName="Input Image" imageGallery={galleryImageList} /> */}
            </DialogContent>
            <DialogContent xs={8} sm={8} md={8}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                label="Name"
                name="name"
                value={name}
                onChange={handleInputChange}
                fullWidth
              />
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Choose Color
                </InputLabel>
                <Select
                  style={{ display: "inline-block" }}
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={colorId}
                  onChange={handleInputChange}
                  inputProps={{
                    name: "simpleSelect",
                    id: "simple-select"
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Choose User
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value="2"
                  >
                    Paris
                  </MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
          </GridContainer>
          <DialogActions>
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
