import { Helmet } from "react-helmet";
import { IoLogoFacebook, IoLogoGoogle, IoLogoAppleAppstore, IoLogoTwitter } from "react-icons/io5";
import { Link } from 'react-router-dom';
import '../login/login.css';
import './signup.css';
import firebase from "config/firebase-config.js";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useNavigate  } from "react-router-dom";

const bgImage = 'https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80'




export default function SignUp() {
    const history = useNavigate ();
    function googleSignIn() {
        const googleAuthProvider = new GoogleAuthProvider();
        return signInWithPopup(firebase, googleAuthProvider)
        .then((res) =>{
          console.log(res);
          history("/admin/dasboard");
        })
        .catch((err)=>{
          console.log(err)
        })
      }
      function facebookSignIn() {
        const facebookAuthProvider = new FacebookAuthProvider();
        return signInWithPopup(firebase, facebookAuthProvider)
        .then((res) =>{
          console.log(res);
          history.push("/admin/dashboard");
        })
        .catch((err)=>{
          console.log(err)
        })
      }
      function twitterSignIn() {
        const twitterAuthProvider = new TwitterAuthProvider();
        return signInWithPopup(firebase, twitterAuthProvider)
        .then((res) =>{
          console.log(res);
          history.push("/admin/dashboard");
        })
        .catch((err)=>{
          console.log(err)
        })
      }
      function appleSignIn() {
        const appleAuthProvider = new OAuthProvider('apple.com');;
        return signInWithPopup(firebase, appleAuthProvider)
        .then((res) =>{
          console.log(res);
          history.push("/admin/dashboard");
        })
        .catch((err)=>{
          console.log(err)
        })
      }


      
                    
                 
                   


    return (
        <div className='SignupWrap d-flex justify-content-center align-items-center' style={{ backgroundImage: `url(${bgImage})` }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Signup</title>
                <link rel="canonical" href="/signup" />
            </Helmet>
            <div className='container'>
                <div className='loginCard'>
                    <div className='socialLoginWrap text-center'>
                        <h2>Register with</h2>
                        <ul className="d-flex justify-content-center align-items-center">
                            <li className="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-44 makeStyles-transparent-65 makeStyles-justIcon-72 makeStyles-customButtonClass-5"><IoLogoFacebook className="MuiButton-label" onClick={facebookSignIn} /></li>
                            <li className="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-44 makeStyles-transparent-65 makeStyles-justIcon-72 makeStyles-customButtonClass-5"><IoLogoGoogle className="MuiButton-label" onClick={googleSignIn} /></li>
                            <li className="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-44 makeStyles-transparent-65 makeStyles-justIcon-72 makeStyles-customButtonClass-5"><IoLogoTwitter className="MuiButton-label" onClick={twitterSignIn} /></li>
                            <li className="MuiButtonBase-root MuiButton-root MuiButton-text makeStyles-button-44 makeStyles-transparent-65 makeStyles-justIcon-72 makeStyles-customButtonClass-5"> <IoLogoAppleAppstore className="MuiButton-label" onClick={appleSignIn}/></li>
                        </ul>
                    </div>
                    <form className="rounded-5 shadow-5-strong p-5">
                        <div className='formInputs'>
                            <input className='userName' type="text" placeholder='User Name' />
                            <input className='email' type="Email" placeholder='Email' />
                            <input className='password' type="Password" placeholder='Password' />
                        </div>
                        <label class="form-remember"><input type="checkbox" />I agree the Terms and Conditions</label>
                        <button type='submit' className='signinBtn'>Sign Up</button>

                        <div className="loginFooter">
                            <p>Don't have an account? <span><Link className="goSign" to="/login">Sign in</Link></span></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}