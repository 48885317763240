import { IoCheckmarkCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import './features.css';
import Loading from '../ContentLoading';
import ReactPlayer from "react-player";



export default function Feature(props) {
    const data = props.dataFeaturs
    if(!data){return <Loading/>}

    const {book_appointment_section} = data[0];

    const {book_title, book_video, book_link, bg_image} = book_appointment_section;
    return (
        <div className='featureHero' style={{ backgroundImage: `url(${bg_image.url})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-7 col-md-6 col-sm-6 col-12'>
                        <div className='reqDemoWrap'>
                            <h2>{book_title}</h2>
                            {/* <form className="d-flex justify-content-center align-items-center">
                                <input type="text" placeholder="Enter your email" />
                                
                            </form> */}
                            <Link to={book_link} className='BtnDemo' type='submit' >Book an appointment <span><IoCheckmarkCircle/></span></Link>
                        </div>
                    </div>
                    <div className='col-lg-5 col-md-6 col-sm-6 col-12'>
                        <div className="VideoWrap">
                            <ReactPlayer height={280} className="video featureVideo" playing controls
                                light="/Images/videobg.png" url={book_video} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}