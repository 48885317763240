import React, { useEffect, useNavigate } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardBody from "../../../components/Card/CardBody.js";
import { Button } from "@material-ui/core";
import CreateModal from "./CreateModal";
import { EditRounded,  RotateRightRounded, Cancel } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents } from "../../../actions/UploadedUI/EventsAction";
import Swal from "sweetalert2";
import { deleteEvents } from "../../../actions/UploadedUI/EventsAction";
// import CardIcon from "components/Card/CardIcon";

const styles = {
  garageTitle: {
    display: "inline-block",
    float: "right",
  },
  rightText: {
    display: "inline-block",
    margin: "10px",
  },
  garageRowTitle: {
    fontSize: "22px",
    fontWeight: "bold",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      textAlign: "right",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);
export default function EventsUI() {
  const classes = useStyles();
  const events=useSelector(state=>state.event.events);
  const dispatch=useDispatch();
  const history = useNavigate();
   useEffect(()=>{
       dispatch(fetchEvents(events))
   },[]);

  const handleDelete = (id) => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          deleteEvents(id);
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
            )
          history.push("admin/EventsUI");
          dispatch(fetchEvents(events));
        }
      })
    }
  const handleEdit = (values) => {
    console.log("The Values that you wish to edit ", values);
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.rightText}>
              <h4 className={classes.rightText}>Events UI</h4>
              <Button className={classes.garageTitle} alignRight>
                <CreateModal />{" "}
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                  <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Location</TableCell>
                      <TableCell align="center">Year</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell align="right">{row.location}</TableCell>
                      <TableCell align="right">{row.year}</TableCell>
                      <TableCell align="right">
                        <EditRounded
                         color="primary"
                          onClick={() => handleEdit(row.id)}
                        />
                        <RotateRightRounded 
                        style={{ color: 'green' }}
                        />
                        <Cancel 
                         style={{ color: 'red' }}
                         onClick = {()=>handleDelete(row.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
