import * as UploadedAction from '../../Types/UploadedUITypes';

const initialState={
    item:'',
    Items:[],
    id:'',
}

const itemsReducers=(state=initialState, action)=>{

    switch(action.type){
        case UploadedAction.ITEMS_LIST:return{
            ...state,
            items:action.payload
        }
        case UploadedAction.ITEMS_ADD:return{
            ...state,
            item:action.payload
        }
        case UploadedAction.ITEMS_EDIT:return{
            ...state,
            item:action.payload,          
        }
        case UploadedAction.ITEMS_DELETE:return{
            ...state
        }
        default:return state;
    }
}

export default itemsReducers;