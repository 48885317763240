
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Loading from 'Components/ContentLoading';
import Button from 'Components/Button';
// Import Swiper styles
import "./swiper-bundle.min.css";

import "./slider.css";


// import Swiper core and required modules
import SwiperCore, {
  Pagination, Navigation, Autoplay
} from 'swiper';
// install Swiper modules



SwiperCore.use([Pagination, Navigation, Autoplay]);



export default function Sliders(props) {

  let data = props.sliderData;

  if (!data) return <Loading />;
  return (
    <>
      <Swiper autoplay={{
  "delay": 17500,
  "disableOnInteraction": false
}}  slidesPerView={1} spaceBetween={30} loop={true} pagination={{
        "clickable": true
      }} navigation={true} className="HeroSlider">
        {data.map(item => (
          <SwiperSlide key={item.id} style={{ backgroundImage: `url(${item.featured_image.large})` }} >
            <div className="sliderContentWrap">
              <h1>{item.title}</h1>
              <p>{item.content.replace(/(<([^>]+)>)/gi, "")}</p>
              <div className="d-flex align-items-center mt-5">
                <a href={item.BtnOne} className={item.image_buttons[0]}><img src={item.button_image_one.url} alt="ddd" /></a>
                <a href={item.BtnTwo} className={item.image_buttons[0]}> <img src={item.button_image_two.url} alt="ddd" /></a>

                <Button Class={item.text_buttons[0]} to={item.BtnOne} BtnContent={item.button_content} />
                <div className={item.show_only_one_button}>
                  <Button Class={item.text_buttons[0]} to={item.BtnTwo} BtnContent={item.button_content_two} />
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};