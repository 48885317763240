import * as UploadedAction from '../../Types/UploadedUITypes';

const initialState={
    song:'',
    songs:[],
    id:'',
}

const SongsReducers=(state=initialState, action)=>{

    switch(action.type){
        case UploadedAction.SONGS_LIST:return{
            ...state,
            songs:action.payload
        }
        case UploadedAction.SONGS_ADD:return{
            ...state,
            song:action.payload
        }
        case UploadedAction.SONGS_EDIT:return{
            ...state,
            song:action.payload,          
        }
        case UploadedAction.SONGS_DELETE:return{
            ...state
        }
        default:return state;
    }
}

export default SongsReducers;