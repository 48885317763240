import './subscribePage.css';


export default function SubHero(props) {

    const {bgImage,Title, Description  } = props;

    return (
        <div className='SubscribeHero' style={{ backgroundImage: `url(${bgImage})` }}>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='subscribeWrap text-center'>
                            <h2>{Title}</h2>
                            <p>{Description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}