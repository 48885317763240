import Button from "../Button";
import React from "react";
import ReactPlayer from "react-player";
import Loading from 'Components/ContentLoading';
import './featured.css';

export default function FeaturedSection(props) {
    const data = props.homeAllData;
  
    if (!data) return <Loading/>;
    
    const {featured__video_overly_image, featured_button_link, featured_description, featured_title, featured_video_link} = data[0];
    
    
    return (
        <div className="container featuredSection">
           <div class="row">
                <div class="col-12 col-sm-12 col-lg-6">
                    <div className="VideoWrap">
                        <ReactPlayer playing controls className="video"
                            light={featured__video_overly_image.url} url={featured_video_link} />
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-lg-6">
                    <div className="featuredSectionContent">
                        <h1>{featured_title}</h1>
                        <p>
                            {featured_description}
                        </p>
                        <Button to={featured_button_link} BtnContent="Let's Go" />
                    </div>
                </div>
            </div> 
        </div>
    )
}