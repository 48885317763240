import { IoLocationSharp, IoCall } from "react-icons/io5";
import Iframe from 'react-iframe';
import './contact.css';
const bgImage = 'https://cosmatherapy.co.uk/wp-content/uploads/2022/09/AdobeStock_289091997-min-1024x683-matt.jpg';

export default function Contact() {



    return (
        <div id="contact">
            {/* end */}
            <div className='ContactWrap d-flex justify-content-center align-items-center' style={{ backgroundImage: `url(${bgImage})` }}>
                <div className='container' id="contact">
                    <div className='row'>
                        <div className='col-lg-5 col-md-5 col-sm-12 col-12'>
                            <h1 className="CONTACtHeading">get in touch</h1>
                            <ul className="contactInfo">
                               {/*  <li>
                                    <h1><span><IoLocationSharp /></span>Find our office</h1>
                                    <p>dddd,sadahgd, asbdhasdsa</p>
                                </li>
                                <li>
                                    <h1><span><IoCall /></span>Give us a ring</h1>
                                    <p>09827738434387</p>
                                </li> */}

                            </ul>
                        </div>
                        <div className='col-lg-7 col-md-7 col-sm-12 col-12'>
                            <div className='loginCard'>
                                <div className='socialLoginWrap text-center'>
                                    <h2>Contact us</h2>
                                </div>


                                <Iframe 
                                    url="https://cosmatherapy.co.uk/contact-form"
                                    width="100%"
                                    height="550px"
                                    id="myId"/>
                                {/* <form className="rounded-5 shadow-5-strong p-5" >

                                        <div className='formInputs'>
                                            <input name="name" className='userName' type="text" placeholder='Full Name' />
                                            <input name="email" className='email' type="Email" placeholder='Email' />
                                            <textarea name="message" className="massage" placeholder="Your Message"></textarea>
                                        </div>
                                        <label class="form-remember"><input type="checkbox" />I'm not a robot</label>
                                        <button type='submit' className='signinBtn'>Send Message</button>
                                </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}