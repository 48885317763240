
import * as UploadedAction from '../../Types/UploadedUITypes';
import axios from 'axios';

const baseUrl =  "https://test.cosmaonline.com/cosma/api";
const token = localStorage.getItem("access_token");

export const fetchItems=()=>{
    return function(dispatch){
        axios.get(baseUrl + '/cosmaresource/item', {
                headers: {
                    'Authorization': 'bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }
        )
        .then(res=>
            {
                const items = res.data;
                dispatch({
                  type:UploadedAction.ITEMS_LIST,
                  payload:items
                 });
            })
        .catch(err=>console.log(err));  
    }
}

export const GETItemById = (Id) => {
  return function (dispatch) {
    axios
      .get(baseUrl + "/ItemUi/GetFamilyById?Id=" + Id, {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        const item = response.data;
        console.log(item);
        dispatch({
          type: UploadedAction.ITEMS_EDIT,
          payload: item,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const CREATEItems = (item) => {
  axios
    .post(baseUrl + "​​/ItemUi​/Save", item, {
      headers: {
        Authorization: "bearer " + token,
        "content-type": "application/json",
      },
    })
    .then(function () {
      return {
        type: UploadedAction.ITEMS_ADD,
        payload: item,
      };
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const deleteItems = (id) => {
  axios
    .post(baseUrl + "/ItemUi/DeleteItem?Id=" + id, {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => console.log(res))
    .catch((err) => console.log(err));

  return {
    type: UploadedAction.ITEMS_DELETE,
    payload: id,
  };
};
