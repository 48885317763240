import * as UploadedAction from '../../Types/UploadedUITypes';

const initialState={
    family:'',
    families:[],
    id:'',
}

const eventsReducers=(state=initialState, action)=>{

    switch(action.type){
        case UploadedAction.FAMILY_LIST:return{
            ...state,
            families:action.payload
        }
        case UploadedAction.FAMILY_ADD:return{
            ...state,
            family:action.payload
        }
        case UploadedAction.FAMILY_EDIT:return{
            ...state,
            family:action.payload,          
        }
        case UploadedAction.FAMILY_DELETE:return{
            ...state
        }
        default:return state;
    }
}

export default eventsReducers;