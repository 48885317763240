import { Link } from 'react-router-dom';
import './blogPage.css';

export default function BlogSidebar(props) {

    const data = props.dataPosts;
    if (!data) { return null }


    return (
        <div className='BlogSidebar'>
            <div className='recentPost'>
                <h2>RECENT POSTS</h2>
                <ul className='postList'>
                    {data.map(item => (
                    <li key={item.id}>
                        <Link to={`/blog/${item.id}`}>{item.title}</Link>
                    </li>
                    ))}
                </ul>
            </div>
            <div className='recentPost'>
                <h2>tags</h2>
                <ul className='postList'>
                    <li>
                        <Link to="/blog">Web design</Link>
                    </li>
                    <li>
                        <Link to="/blog">Artificial Intelligence</Link>
                    </li>
                    <li>
                        <Link to="/blog">Artificial Intelligence</Link>
                    </li>
                    <li>
                        <Link to="/blog">Artificial Intelligence</Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}