import { Link } from "react-router-dom";
import Loading from 'Components/ContentLoading';


export default function Elevate (props) {
    const data = props.homeAllData;
    if (!data) return <Loading />;

    const {elevate_title, elevate_description, elevate_link, elevate_image, elevate_background_color} = data[0].elevate;

    return(
        <div className="elevateCosma" style={{background: `${elevate_background_color}` }}>
            <div className="row container m-auto">
                <div className="col-lg-8">
                    <div className="elevateContent">
                        <h1>{elevate_title}</h1>
                        <p className="contentOne" >{elevate_description}</p>
                        <p className="contentTwo">Already have an account? <span><Link className="log" to="/login">Log in</Link></span></p>
                        <Link className="getStarted" to={elevate_link}>Get Started</Link>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="elevateImage">
                        <img src={elevate_image.url} alt="ssd" />
                    </div>
                </div>
            </div>
        </div>
    )
}