import { Link } from "react-router-dom";
import axios from 'axios';
import React from 'react';


export default function Book () {

    const [dataOther, setDataAPIOther] = React.useState(null);
    const APIOther = "https://cosmatherapy.co.uk/wp-json/wp/v2/service_singleconten";

    React.useEffect(() => {
        
        axios.get(APIOther).then((res) => {
            setDataAPIOther(res.data);
        });

      }, []);
      if(!dataOther){return null}

      const {heading, btn_link, bg_color} = dataOther[1].acf.research_organisations_book_section;
    return(
        <div className="elevateCosma" style={{background: `${bg_color}` }}>
            <div className="row container m-auto">
                <div className="col-lg-8 col-md-8 col-sm-6 col-12">
                    <div className="elevateContent">
                        <h1>{heading}</h1>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="elevateBtn">
                        <Link className="getStarted" to={btn_link}>Book an appointment</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}