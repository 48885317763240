import * as OrganizationAction from '../../Types/organizationTypes';

const initialState={
    allOrgUser:[],
    orgUser:'',
    id:'',
}

const orgUserReducer=(state=initialState, action)=>{

    switch(action.type){
        case OrganizationAction.ORG_USER_LIST:return{
            ...state,
            allOrgUser:action.payload
        }
        case OrganizationAction.ORG_EDIT_USER:
            return{
                orgUser:action.payload,
                id:action.id
        }
        case OrganizationAction.ORG_ADD_USER:
            return{
                ...state,
                orgUser:action.payload
        }
        case OrganizationAction.ORG_DELETE_USER:return{
            ...state
        }
        default:return state;
    }
}
export default orgUserReducer;