import React from 'react';
import { Link } from 'react-router-dom';
import Loading from 'Components/ContentLoading';
import { IoChevronForward } from "react-icons/io5";
import './services.css';



export default function Services(props) {

    const data = props.homeAllData;
    const dataServices = props.dataServices;
    if (!data) return <Loading/>;
    if (!dataServices) return <Loading/>;

    const {services_title} = data[0];


    return (
        <div className='serviceswrap'>
            <div className='container-fluid m-auto'>
                <h1 className='servicesTitle'>{services_title}</h1>
                <div className="row">
                    {dataServices.map(item => (
                        <div key={item.id} className="col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className={'card servicesCard ' + (item.services_link.services_card_class)}>
                                <div className="card-body text-center">
                                    <h1 className="card-title text-center">{item.title}</h1>
                                    <p className="card-text">
                                        {item.content.replace(/(<([^>]+)>)/gi, "")}
                                    </p>
                                    <Link className="servicesBtn" to={item.services_link.services_links}>
                                        Learn More<span><IoChevronForward className='right' /></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}



                </div>
            </div>
        </div>
    )
}