const Data = [
    {
        id: 1,
        du: 'Monthly',
        title: 'Single Subscriber',
        price: '45',
    },
    // {
    //     id: 2,
    //     du: 'Annually',
    //     title: 'Single Subscriber',
    //     price: '135',
    // },
    // {
    //     id: 3,
    //     title: 'Small Businesses',
    //     price: '40',
    //     du: 'Quarterly'
    // },
    // {
    //     id: 4,
    //     title: 'Small Businesses',
    //     price: '135',
    //     du: 'Annually'
    // },
    // {
    //     id: 5,
    //     title: 'Medium Business',
    //     price: '36',
    //     du: 'Quarterly'
    // },
    // {
    //     id: 6,
    //     title: 'Medium Business',
    //     price: '120',
    //     du: 'Annually'
    // },

]

export default Data;