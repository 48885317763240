import './blogSection.css';
import Loading from 'Components/ContentLoading';
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "../HeroSlider/swiper-bundle.min.css";

import "./blogSection.css";


// import Swiper core and required modules
import SwiperCore, {
    Pagination, Navigation
} from 'swiper';
import { Link } from 'react-router-dom';



SwiperCore.use([Pagination, Navigation]);


// end

export default function BlogSection(props) {
    const data = props.dataPosts;
    if(!data) {return <Loading/>}
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                    clickable: true
                }}
                breakpoints={{
                    "@0.00": {
                        slidesPerView: 1,
                        spaceBetween: 10
                    },
                    "@0.75": {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    "@1.00": {
                        slidesPerView: 3,
                        spaceBetween: 20
                    },
                    "@1.50": {
                        slidesPerView: 4,
                        spaceBetween: 20
                    }
                }}
                className="BlogSlider"
            >

                {data.map(item => (

                    <SwiperSlide key={item.id}>
                        <div className='blogItem'>
                            <div className='blogItemHeader'>
                                <img src={item.featured_image.medium} alt="sss" />
                                <div className='cateWrap'>
                                    <h1>{item.post_category}</h1>
                                </div>
                            </div>
                            <div className='blogItemFooter'>
                                <Link to={`/blog/${item.id}`}><h1>{item.title}</h1></Link>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}


            </Swiper>
        </>
    );
}