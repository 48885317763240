import * as OrganizationAction from "../../Types/organizationTypes";
import axios from "axios";

const baseUrl = "https://test.cosmaonline.com/cosma/api";
const token = localStorage.getItem("access_token");

export const fetchDoctors = () => {
  return function (dispatch) {
    axios
      .get(baseUrl + "/Doctor/GetDoctors", {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const doctors = res.data;
        dispatch({
          type: OrganizationAction.ORG_DOCTOR_LIST,
          payload: doctors,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const GETDoctorsById = (DocId) => {
  return function (dispatch) {
    axios
      .get(baseUrl + "/Doctor/GetDoctorById?Id=" + DocId, {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        const doctor = response.data;
        console.log(doctor);
        dispatch({
          type: OrganizationAction.ORG_EDIT_DOCTOR,
          payload: doctor,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const CREATEDoctor = (doctor) => {
  axios
    .post(baseUrl + "/Doctor/Save", doctor, {
      headers: {
        Authorization: "bearer " + token,
        "content-type": "application/json",
      },
    })
    .then(function () {
      return {
        type: OrganizationAction.ORG_ADD_DOCTOR,
        payload: doctor,
      };
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const deleteDoctor = (DocId) => {
  axios
    .delete(baseUrl + "Doctor/GetDoctorById?Id=" + DocId, {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => console.log(res))
    .catch((err) => console.log(err));

  return {
    type: OrganizationAction.ORG_DELETE_DOCTOR,
    payload: DocId,
  };
};
