import React from 'react';
import { Helmet } from "react-helmet";
import BlogSlider from '../../Components/BlogSection';
import Hero from '../../Components/SubscribePage/Hero';
import BlogList from '../../Components/BlogPage/BlogList';
import BlogSidebar from '../../Components/BlogPage/BlogSidebar';
import axios from 'axios';
import Loading from 'Components/ContentLoading';
import './blog.css';





export default function Blog (){
    const [dataPosts, setData] = React.useState(null);
    const [dataBreadcumbs, setDataBreadcumbs] = React.useState(null);
    const [dataSeo, setDataSeo] = React.useState(null);
    
    const APIPosts = "https://cosmatherapy.co.uk/wp-json/wl/v1/posts";
    const APIBreadcumbs = "https://cosmatherapy.co.uk/wp-json/wl/v1/breadcrumbs";
    const APISeo = "https://cosmatherapy.co.uk/wp-json/wp/v2/seo";

    React.useEffect(() => {
        axios.get(APISeo).then((response) => {
            setDataSeo(response.data);
        });
        axios.get(APIPosts).then((res) => {
            setData(res.data);
        });
        axios.get(APIBreadcumbs).then((res) => {
            setDataBreadcumbs(res.data);
        });

      }, []);

      
      const breadcumbss = dataBreadcumbs;
      const Seo = dataSeo;
      if(!Seo){return <Loading/>}
      if (!breadcumbss) { return null }

      const Title = breadcumbss[4].title;
      const Description = breadcumbss[4].content.replace(/(<([^>]+)>)/gi, "");
      const bgImage = breadcumbss[4].featured_image.medium;
      const {meta_description, meta_keywords} = Seo[0].acf.blog_page;


      

      console.log(breadcumbss)
        return (
            <div className='blogPageWrap'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{breadcumbss[0].title}</title>
                    <meta name="description" content={meta_description} />
                    <meta name="keywords" content={meta_keywords}/>
                    <link rel="canonical" href="/blog" />
                </Helmet>
                <Hero bgImage={bgImage} Title={Title} Description={Description} />
                <div className='BlogSlidWrap'>
                    <div className='container'>
                        <BlogSlider dataPosts={dataPosts} />
                    </div>
                </div>
                <div className='blogDownWrap'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8 col-md-7 col-sm-6 col-12'>
                                <BlogList dataPosts={dataPosts}/>
                            </div>
                            <div className='col-lg-4 col-md-5 col-sm-6 col-12'>
                                <BlogSidebar dataPosts={dataPosts} />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    
}