import * as OrganizationAction from '../../Types/organizationTypes';

const initialState={
    doctor:'',
    allDoctors:[],
    id:'',
}

const doctorsReducer=(state=initialState, action)=>{

    switch(action.type){
        case OrganizationAction.ORG_DOCTOR_LIST:return{
            ...state,
            allDoctors:action.payload
        }
        case OrganizationAction.ORG_EDIT_DOCTOR:
            return{
                doctor:action.payload,
                id:action.id
        }
        case OrganizationAction.ORG_ADD_DOCTOR:
            return{
                ...state,
                doctor:action.payload
        }
        case OrganizationAction.ORG_DELETE_DOCTOR:return{
            ...state
        }
        default:return state;
    }
}

export default doctorsReducer;