import { Helmet } from "react-helmet";
import Hero from '../../Components/SubscribePage/Hero';
import Pricing from '../../Components/SubscribePage/Pricing';
import Faqs from '../../Components/SubscribePage/Faq';
// import { useState } from 'react';
// import product from '../../Components/data';
import axios from 'axios';
import Loading from '../../Components/ContentLoading';
import React from 'react';
import './subscribe.css';
import Contact from "../../Components/Contact";


export default function Subscribe(props) {

    const [dataBreadcumbs, setDataBreadcumbs] = React.useState(null);
    const [dataPricing, setDataPricings] = React.useState(null);
    const [dataPricingOfferOne, setDataPricingsOfferOne] = React.useState(null);
    const [dataPricingOfferTwo, setDataPricingsOfferTwo] = React.useState(null);
    const [dataPricingOfferThree, setDataPricingsOfferThree] = React.useState(null);
    const [dataFaq, setFaq] = React.useState(null);
    const [dataSubAllCont, setSubAllCont] = React.useState(null);
    const [dataSeo, setDataSeo] = React.useState(null);

    const APIBreadcumbs = "https://cosmatherapy.co.uk/wp-json/wl/v1/breadcrumbs";
    const APIPricing = "https://cosmatherapy.co.uk/wp-json/wp/v2/subscribe_page_subs";
    const APIPricingOfferOne = "https://cosmatherapy.co.uk/wp-json/wp/v2/single_subscribertwo";
    const APIPricingOfferTwo = "https://cosmatherapy.co.uk/wp-json/wp/v2/small_businessestwo";
    const APIPricingOfferThree = "https://cosmatherapy.co.uk/wp-json/wp/v2/medium_businesstwo";
    const APIFaq = "https://cosmatherapy.co.uk/wp-json/wl/v1/faqs";
    const APISubAllCont = "https://cosmatherapy.co.uk/wp-json/wp/v2/subscribe_all_conten";
    const APISeo = "https://cosmatherapy.co.uk/wp-json/wp/v2/seo";

    React.useEffect(() => {
        
        axios.get(APIBreadcumbs).then((res) => {
            setDataBreadcumbs(res.data);
        });
        axios.get(APIPricing).then((rese) => {
            setDataPricings(rese.data);
        });
        axios.get(APIPricingOfferOne).then((rese) => {
            setDataPricingsOfferOne(rese.data);
        });
        axios.get(APIPricingOfferTwo).then((rese) => {
            setDataPricingsOfferTwo(rese.data);
        });
        axios.get(APIPricingOfferThree).then((rese) => {
            setDataPricingsOfferThree(rese.data);
        });
        axios.get(APIFaq).then((rese) => {
            setFaq(rese.data);
        });
        axios.get(APISubAllCont).then((rese) => {
            setSubAllCont(rese.data);
        });
        axios.get(APISeo).then((response) => {
            setDataSeo(response.data);
        });

      }, []);


    const breadcumbs = dataBreadcumbs;
    const SubAllCont = dataSubAllCont;
    const Seo = dataSeo;
    if(!Seo){return <Loading/>}
      
      
    if(!breadcumbs){ return <Loading />}
    if(!SubAllCont){ return <Loading />}
    
    const {featured_image, title, content } = breadcumbs[5];

    const {des,section_bg, link } = SubAllCont[0].acf.licensing_or_distributing_section;
    const {meta_description, meta_keywords} = Seo[0].acf.subscribe_page;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Subscribe</title>
                <meta name="description" content={meta_description} />
                <meta name="keywords" content={meta_keywords}/>
                <link rel="canonical" href="/subscribe" />
            </Helmet>
            <Hero bgImage={featured_image.large} Title={title} Description={content.replace(/(<([^>]+)>)/gi, "")} />
            <Pricing
                dataPricing={dataPricing}
                dataPricingOfferOne={dataPricingOfferOne}
                dataPricingOfferTwo={dataPricingOfferTwo}
                dataPricingOfferThree={dataPricingOfferThree}
            />
            <div className="elevateCosma" style={{background: `${section_bg}` }}>
                <div className="row container m-auto">
                    <div className="col-lg-8 col-md-8 col-sm-6 col-12">
                        <div className="elevateContent">
                            <h1>{SubAllCont[0].acf.licensing_or_distributing_section.title}</h1>
                            <p className="contitts">{des}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                        <div className="elevateBtn">
                            <a className="getStarted" href={link}>Contact us</a>
                        </div>
                    </div>
                </div>
            </div>
            <Faqs dataFaq={dataFaq} />
            <Contact/>
        </>
    )
}

