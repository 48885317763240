import React from "react";
import GridContainer from "../../components/Grid/GridContainer";
import ChartistGraph from "react-chartist";
import GridItem from "../../components/Grid/GridItem";
import { makeStyles } from "@material-ui/core/styles";
// import { Chart, Title } from "@devexpress/dx-react-chart";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardFooter from "../../components/Card/CardFooter.js";
import styles from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import CardBody from "../../components/Card/CardBody";
import CardIcon from "../../components/Card/CardIcon";
import { AccessTime, ArrowUpward,Language } from "@material-ui/icons";
import Table from "../../components/Table/Table.js";
import VectorMaps from "../Maps/VectorMap";
import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
  pieChart
} from "../../variables/charts.js";
import Timeline from "@material-ui/icons/Timeline";

const useStyles = makeStyles(styles);

const MainDashboard=()=>{
    const classes =useStyles();

    // import priceImage1 from "assets/img/card-2.jpeg";
    // import priceImage2 from "assets/img/card-3.jpeg";
    // import priceImage3 from "assets/img/card-1.jpeg";
    
    const us_flag = require("assets/img/flags/US.png").default;
    const de_flag = require("assets/img/flags/DE.png").default;
    const au_flag = require("assets/img/flags/AU.png").default;
    const gb_flag = require("assets/img/flags/GB.png").default;
    const ro_flag = require("assets/img/flags/RO.png").default;
    const br_flag = require("assets/img/flags/BR.png").default;
    
    var mapData = {
      AU: 760,
      BR: 550,
      CA: 120,
      DE: 1300,
      FR: 540,
      GB: 690,
      GE: 200,
      IN: 200,
      RO: 600,
      RU: 300,
      US: 2920,
    };



    return(
        <div>
            <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader>
            <h4 style={{margin:"0px,0px,10px,10px", padding:"0px,0px,5px,5px"}}>Total Income
              <h3 className={classes.cardTitle}>
                47,200 <small>GB</small>
              </h3>  </h4>
            </CardHeader>
            <CardBody>
          
            </CardBody>
            {/* <CardFooter stats>
             <Chart/>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader>
            <h4>Total Players
              <h3 className={classes.cardTitle}>
                49/50 <small>GB</small>
              </h3>
            </h4>
            </CardHeader>
            <CardBody>
           
            </CardBody>
            {/* <CardFooter stats>
             <Chart/>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader>
            <h4>Total Income
              <h3 className={classes.cardTitle}>
                49/50 <small>GB</small>
              </h3>
            </h4>
            </CardHeader>
            <CardBody>
            
            </CardBody>
            {/* <CardFooter stats>
             <Chart/>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <h5> <b style={{color:"red"}}>{'__'}</b> {`  `}Total Income </h5>
            {/* <CardHeader> */}
              <h3 style={{marginLeft:"20px"}} className={classes.cardTitle}>
                47,300 {' '}<small>GB</small>
              </h3>
              <ChartistGraph 
                color="primary"
                className="ct-chart"
                data={dailySalesChart.data}
                type="Line"
                //options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            {/* </CardHeader>
            <CardBody>
            
            </CardBody> */}
            {/* <CardFooter stats>
             <Chart/>
            </CardFooter> */}
          </Card>
        </GridItem>
        </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="success" icon>
              <CardIcon color="success">
                <Language />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Global Sales by Top Locations
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="space-between">
                <GridItem xs={12} sm={12} md={5}>
                  <Table
                    tableData={[
                      [
                        <img src={us_flag} alt="us_flag" key={"flag"} />,
                        "USA",
                        "2.920",
                        "53.23%",
                      ],
                      [
                        <img src={de_flag} alt="us_flag" key={"flag"} />,
                        "Germany",
                        "1.300",
                        "20.43%",
                      ],
                      [
                        <img src={au_flag} alt="us_flag" key={"flag"} />,
                        "Australia",
                        "760",
                        "10.35%",
                      ],
                      [
                        <img src={gb_flag} alt="us_flag" key={"flag"} />,
                        "United Kingdom",
                        "690",
                        "7.87%",
                      ],
                      [
                        <img src={ro_flag} alt="us_flag" key={"flag"} />,
                        "Romania",
                        "600",
                        "5.94%",
                      ],
                      [
                        <img src={br_flag} alt="us_flag" key={"flag"} />,
                        "Brasil",
                        "550",
                        "4.34%",
                      ],
                    ]}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={7}>
                  <VectorMaps
                    map={"world_mill"}
                    backgroundColor="transparent"
                    zoomOnScroll={true}
                    // containerStyle={{
                    //   width: "100%",
                    //   height: "100px",
                    // }}
                    containerClassName="map"
                    regionStyle={{
                      initial: {
                        fill: "#e4e4e4",
                        "fill-opacity": 0.9,
                        stroke: "none",
                        "stroke-width": 0,
                        "stroke-opacity": 0,
                      },
                    }}
                    series={{
                      regions: [
                        {
                          values: mapData,
                          scale: ["#AAAAAA", "#444444"],
                          normalizeFunction: "polynomial",
                        },
                      ],
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Daily Sales</h4>
              <p className={classes.cardCategory}>
                <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today sales.
              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated 4 minutes ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Email Subscriptions</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={completedTasksChart.data}
                type="Line"
                options={completedTasksChart.options}
                listener={completedTasksChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Completed Tasks</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent 2 days ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader>
            <h4 style={{margin:"0px,0px,10px,10px", padding:"0px,0px,5px,5px"}}>Total Income
              <h3 className={classes.cardTitle}>
                47,200 <small>GB</small>
              </h3>  </h4>
            </CardHeader>
            <CardBody>
          
            </CardBody>
            {/* <CardFooter stats>
             <Chart/>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader>
            <h4>Total Players
              <h3 className={classes.cardTitle}>
                49/50 <small>GB</small>
              </h3>
            </h4>
            </CardHeader>
            <CardBody>
           
            </CardBody>
            {/* <CardFooter stats>
             <Chart/>
            </CardFooter> */}
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader>
            <h4>Total Income
              <h3 className={classes.cardTitle}>
                49/50 <small>GB</small>
              </h3>
            </h4>
            </CardHeader>
            <CardBody>
            
            </CardBody>
            {/* <CardFooter stats>
             <Chart/>
            </CardFooter> */}
          </Card>
        </GridItem>
        </GridContainer>
      <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Recent Order</h4>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={["Name", "Email", "Licence Quantity" ,"Status"]}
                tableData={[
                  ["Dakota Rice", "datota@gmail.com","1", "Done"],
                  ["Minerva Hooper", "hooper@gmail.com","2",  "Done"],
                  ["Sage Rodriguez", "saga@gmail.com","3", "Done"],
                  ["Philip Chaney", "philip@gmail.com","4", "Done"],
                  ["Philip Chaney", "philip@gmail.com","4", "Done"]
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Pie Chart</h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph md={6}
                data={pieChart.data}
                type="Pie"
                options={pieChart.options}
              />
            </CardBody>
            <CardFooter stats className={classes.cardFooter}>
              <h6 className={classes.legendTitle}>Legend</h6>
              <i className={"fas fa-circle " + classes.primary} /> Apple{` `}
              <i className={"fas fa-circle " + classes.warning} /> Samsung
              {` `}
              <i className={"fas fa-circle " + classes.danger} /> Windows Phone
              {` `}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
        </div>
    );
}
export default MainDashboard;