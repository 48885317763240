import { useLocation } from 'react-router-dom';
import { useState } from "react";
import renderHTML from "react-render-html";
import Moment from "react-moment";
import Iframe from 'react-iframe';
import React from 'react';
import axios from 'axios';

export default function Single() {

	const location = useLocation();
	const path = (location.pathname.split("/")[2]);

	const [post, setPost] = useState({});


	React.useEffect(() => {
        
        axios.get("https://cosmatherapy.co.uk/wp-json/wp/v2/posts/" + path).then((res) => {
            setPost(res.data);
        });

      }, [path]);


	//   const data = JSON.stringify(post);
	//   const oobj = JSON.parse(data);
	  

	//   console.log(tRen.rendered)


	return (

		<div className='container mt-5 post_wrap'>
			{ Object.keys( post ).length ? (
					<div className="mt-5 posts-container">
						<div key={post.id} className="card border-dark mb-3">
							<div className="card-header">
								{renderHTML( post.title.rendered )}
							</div>
							<div className="card-body">
								<div className="card-text post-content">{ renderHTML( post.content.rendered ) }</div>
							</div>
							<div className="card-footer"><Moment fromNow >{post.date}</Moment></div>
						</div>
					</div>
				) : '' }

				<div className='comment'>
					<Iframe src='https://cosmatherapy.co.uk/blog-comment/'
					width="100%"
					height="400px"
					/>
				</div>
		</div>
	)
}