import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CardHeader from "../../../components/Card/CardHeader.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { CREATEOrgUser } from "../../../actions/OrgActions/orgUserActions";
import { useNavigate  } from "react-router";
import { useDispatch, useSelector } from "react-redux";


const styles = {
    garageTitle: {
        display: "inline-block",
      },
  rightText: {
    display: "inline-block",
    float: "right",
    color: "#FFFFFF",
  },
  textMargin: {
    marginTop: "10px",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      textAlign: "right",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const InitialState={
  "id": 0,
  "name": "",
  "contactEmail": "",
  "address1": "",
  "address2": "",
  "city": "",
  "state": "",
  "country": "",
  "isActive": true,
  "modifiedTime": "2021-10-12T08:03:10.107Z",
  "modifiedBy": ""
}


const useStyles = makeStyles(styles);

export default function FormDialog() {
  const [open, SetOpen] = useState(false);
  const [orgUser, SetOrgUser] = useState(InitialState);
  const [error, SetError] = useState();
  const history = useNavigate ();
  const classes = useStyles();

  const orgUsers =useSelector(state=>state.orgUser.orgUser.id);
  console.log(orgUsers);
  const dispatch=useDispatch();
  useEffect(()=>{
      // dispatch(GETDoctorsById())
  },[]);

  const handleClickOpen = () => {
    SetOpen(true);
  };
  const {firstName, lastName, age , religion , gender , orgId , diagnosis , stage,address1,address2,city,state,country} = orgUser;
  const handleInputChange=(e)=>{
   let {name , value } = e.target;
   SetOrgUser({ ...orgUser, [name]: value });
  }
  const formSubmitHandler= (e)=>{
    // e.preventDefault;
    if(!firstName || !lastName || !address1 || !country)
    {
      SetError("Please input All Fields")
    }else{
      dispatch(CREATEOrgUser(orgUser));
      history.push("/admin/orgUser");
      SetOpen(false);
    }
  }
  const handleClose = () => {
    SetOpen(false);
  };

  return (
    <div>
      <Button
        className={classes.rightText}
        variant="outlined"
        onClick={handleClickOpen}
      >
        Add New
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="Add-OrgUser"
        className={classes.cardTitleWhite}
      >
        <CardHeader color="success">
          <DialogTitle id="Add-OrgUser" className={classes.textMargin}>
            {" "}
            Add Org User
          </DialogTitle>
        </CardHeader>
        <form onSubmit={formSubmitHandler}>
        <DialogContent>
          <GridContainer>
          <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                  {error && <h5 style={{color:"red"}}>{error}</h5>}
              </GridItem>
          </GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={handleInputChange}
                label="First Name"
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="lastname"
                name="lastName"
                value={lastName}
                onChange={handleInputChange}
                label="Last Name"
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="age"
                name="age"
                value={age}
                onChange={handleInputChange}
                label="Age"
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="religion"
                name="religion"
                value={religion}
                onChange={handleInputChange}
                label="Religion"
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <InputLabel  id="select-label">Gender</InputLabel>
              <Select
                labelId="select-label"
                id="gender"
                name="gender"
                value={gender}
                onChange={handleInputChange}
                label="gender"
              >
                <MenuItem value={10}>Male</MenuItem>
                <MenuItem value={20}>Female</MenuItem>
                <MenuItem value={30}>Other</MenuItem>
              </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth >
              <InputLabel  id="demo-simple-select-label">Organization</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="orgId"
                value={orgId}
                onChange={handleInputChange}
                label="Organization"
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="diagnosis"
                name="diagnosis"
                value={diagnosis}
                onChange={handleInputChange}
                label="Diagnosis"
                variant="outlined"
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="stage"
                name="stage"
                value={stage}
                onChange={handleInputChange}
                label="Stage"
                fullWidth
                variant="outlined"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="address1"
                name="address1"
                value={address1}
                onChange={handleInputChange}
                label=" First Line Address"
                fullWidth
                variant="outlined"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="address2"
                name="address2"
                value={address2}
                onChange={handleInputChange}
                label="Second Line Address"
                fullWidth
                variant="outlined"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="city"
                name="city"
                value={city}
                onChange={handleInputChange}
                label="City"
                variant="outlined"
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="state"
                name="state"
                value={state}
                onChange={handleInputChange}
                label="State"
                variant="outlined"
                fullWidth
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <TextField
                autoFocus
                required
                margin="dense"
                id="country"
                name="country"
                value={country}
                onChange={handleInputChange}
                label="Country"
                variant="outlined"
                fullWidth
              />
            </GridItem>
          </GridContainer>
        <hr />
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
