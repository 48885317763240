import * as React from "react";
import Paper from "@material-ui/core/Paper";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {
  Icon,
  TableBody,
  TableCell,
  Table,
  TableContainer,
  TableRow,
  MenuItem,
  Select,
  makeStyles,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardIcon from "../../components/Card/CardIcon";
import CardFooter from "../../components/Card/CardFooter";
import Danger from "../../components/Typography/Danger";
import Warning from "../../components/Typography/Warning";
import CardBody from "../../components/Card/CardBody";
import { Timeline } from "@material-ui/icons";
import ChartistGraph from "react-chartist";
import { colouredLinesChart } from "../../variables/charts";
import styles from "../../assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

const useStyles = makeStyles(styles);
// import Table from "components/Table/Table.js";


const UserDashboard=()=> {
  const [simpleSelect, setSimpleSelect] = React.useState("");
    // const { data: chartData } = data;
    const classes = useStyles();
    const handleSimple = (event) => {
      setSimpleSelect(event.target.value);
    };

    return (
      <div>
        <GridContainer>
          <GridItem md={6} xs={12} sm={12}>
            <Card>
              <CardHeader color="success">
                <div className={classes.rightText}>
                  {/* <h4 style={{ display: "inline-block", marginRight: "30px" }}>
                   User Detail
                  </h4> */}
                 {/* <Select
                    style={{ display: "inline-block" }}
                   // value={selected}
                    sx={{ minWidth: 120 }}
                    label="Select User"
                    id="demo-simple-select"
                    value="23"
                    //onChange={handleChange}
                  >
                    <MenuItem value={10}>Aaqib</MenuItem>
                    <MenuItem value={20}>Karteka</MenuItem>
                    <MenuItem value={30}>Bojan</MenuItem>
                  </Select> */}
                    <GridItem xs={12} sm={6} md={5} lg={5} >
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          Choose User
                        </InputLabel>
                        <Select  style={{ display: "inline-block" }}
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={simpleSelect}
                          onChange={handleSimple}
                          inputProps={{
                            name: "simpleSelect",
                            id: "simple-select",
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            Choose User
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="2"
                          >
                            Paris
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="3"
                          >
                            Bucharest
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="4"
                          >
                            Rome
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="5"
                          >
                            New York
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="6"
                          >
                            Miami
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="7"
                          >
                            Piatra Neamt
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="8"
                          >
                            Paris
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="9"
                          >
                            Bucharest
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="10"
                          >
                            Rome
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="11"
                          >
                            New York
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="12"
                          >
                            Miami
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="13"
                          >
                            Piatra Neamt
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="14"
                          >
                            Paris
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="15"
                          >
                            Bucharest
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="16"
                          >
                            Rome
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="17"
                          >
                            New York
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="18"
                          >
                            Miami
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="19"
                          >
                            Piatra Neamt
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                </div>
              </CardHeader>
              <CardBody>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" component="td" scope="row">
                          {" "}
                          <b>UserName</b>{" "}
                        </TableCell>
                        <TableCell align="center" component="td" scope="row">
                          {" "}
                          Aaqib Idrees
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" component="td" scope="row">
                          <b> DOB</b>{" "}
                        </TableCell>
                        <TableCell align="center" component="td" scope="row">
                          03/02/1997{" "}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" component="td" scope="row">
                          {" "}
                          <b>AGE</b>{" "}
                        </TableCell>
                        <TableCell align="center" component="td" scope="row">
                          24{" "}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" component="td" scope="row">
                          <b> FIRST LANGUAGE</b>{" "}
                        </TableCell>
                        <TableCell align="center" component="td" scope="row">
                          {" "}
                          URDU{" "}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" component="td" scope="row">
                          <b> City </b>
                        </TableCell>
                        <TableCell align="center" component="td" scope="row">
                          {" "}
                          Lahore{" "}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" component="td" scope="row">
                          <b> Country </b>
                        </TableCell>
                        <TableCell align="center" component="td" scope="row">
                          {" "}
                          Pakistan{" "}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Coloured Lines Chart <small>- Rounded</small>
              </h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={colouredLinesChart.data}
                type="Line"
                options={colouredLinesChart.options}
                listener={colouredLinesChart.animation}
              />
            </CardBody>
          </Card>
        </GridItem>
          </GridContainer>
          <div style={{marginTop:"30px"}}>
            <GridContainer>
          <GridItem
            md={4}
            xs={12}
            sm={12}
          >
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <Icon>content_copy</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Used Space</p>
                <h3 className={classes.cardTitle}>
                  49/50 <small>GB</small>
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Danger>
                    <Warning />
                  </Danger>
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    Get more space
                  </a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Coloured Lines Chart <small>- Rounded</small>
              </h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={colouredLinesChart.data}
                type="Line"
                options={colouredLinesChart.options}
                listener={colouredLinesChart.animation}
              />
            </CardBody>
          </Card>
        </GridItem>
        </GridContainer>
        </div>
        </div>
    );
}

export default UserDashboard;
