import React from 'react';
import FeaturedSection from "../Components/FeaturedSection";
import Slider from "../Components/HeroSlider/index";
import Services from "../Components/Services";
import { Helmet } from "react-helmet";
import './home.css';
import Pricing from "../Components/Pricing";
import BlogSection from "../Components/BlogSection";
// import { IoPerson } from "react-icons/io5";
import axios from 'axios';
import Loading from 'Components/ContentLoading';
import './homeResponsive.css';
import Newsletter from 'Components/Newsletter';
import Elevate from 'Components/Elevate';



// newsletter
const heading = 'Subscribe to our Newsletter';
const shortDes = 'Please enter your email and subscribe for updates.';

export default function Home(props) {
   
    const [data, setData] = React.useState(null);
    const [dataHome, setDataHome] = React.useState(null);
    const [dataServices, setDataServices] = React.useState(null);
    const [dataPricing, setDataPricings] = React.useState(null);
    const [dataPricingOfferOne, setDataPricingsOfferOne] = React.useState(null);
    const [dataPricingOfferTwo, setDataPricingsOfferTwo] = React.useState(null);
    const [dataPricingOfferThree, setDataPricingsOfferThree] = React.useState(null);
    const [dataPosts, setDataPosts] = React.useState(null);
    const [dataSeo, setDataSeo] = React.useState(null);

    const API = "https://cosmatherapy.co.uk/wp-json/wl/v1/homeheroslider";
    const APIHome = "https://cosmatherapy.co.uk/wp-json/wl/v1/home_all_contents";
    const APIServices = "https://cosmatherapy.co.uk/wp-json/wl/v1/services";
    const APIPricing = "https://cosmatherapy.co.uk/wp-json/wp/v2/subscribe";
    const APIPricingOfferOne = "https://cosmatherapy.co.uk/wp-json/wp/v2/single_subscriber";
    const APIPricingOfferTwo = "https://cosmatherapy.co.uk/wp-json/wp/v2/small_businesses";
    const APIPricingOfferThree = "https://cosmatherapy.co.uk/wp-json/wp/v2/medium_business";
    const APIPosts = "https://cosmatherapy.co.uk/wp-json/wl/v1/posts";
    const APISeo = "https://cosmatherapy.co.uk/wp-json/wp/v2/seo";

    React.useEffect(() => {
        axios.get(APISeo).then((response) => {
            setDataSeo(response.data);
        });
        axios.get(API).then((response) => {
          setData(response.data);
        });
        axios.get(APIHome).then((res) => {
            setDataHome(res.data);
        });
        axios.get(APIServices).then((rese) => {
            setDataServices(rese.data);
        });
        axios.get(APIPricing).then((rese) => {
            setDataPricings(rese.data);
        });
        axios.get(APIPricingOfferOne).then((rese) => {
            setDataPricingsOfferOne(rese.data);
        });
        axios.get(APIPricingOfferTwo).then((rese) => {
            setDataPricingsOfferTwo(rese.data);
        });
        axios.get(APIPricingOfferThree).then((rese) => {
            setDataPricingsOfferThree(rese.data);
        });
        axios.get(APIPosts).then((rese) => {
            setDataPosts(rese.data);
        });

      }, []);
    
     
      let sliderData = data;
      
      const homeAllData = dataHome;
      const Seo = dataSeo;
      if(!Seo){return <Loading/>}
      
      const {meta_description, meta_keywords} = Seo[0].acf.home_page;

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home</title>
                <meta name="description" content={meta_description} />
                <meta name="keywords" content={meta_keywords}/>
                <link rel="canonical" href="/" />
            </Helmet>
            <Slider sliderData={sliderData} />
            <FeaturedSection homeAllData={homeAllData} />
            <Services homeAllData={homeAllData} dataServices={dataServices}/>
            {/* elevateCosma */}
            <Elevate homeAllData={homeAllData}/>
            {/* elevateCosma */}
            <Pricing 
            dataPricing={dataPricing} 
            dataPricingOfferOne={dataPricingOfferOne}
            dataPricingOfferTwo={dataPricingOfferTwo}
            dataPricingOfferThree={dataPricingOfferThree}
            
            />
            {/* contact us end */}
            
            <Newsletter heading = {heading} shortDes={shortDes} homeAllData={homeAllData}/>

            {/* contact us end */}
            {/* Blog start */}
            <div className="BlogWraper">
                <div className="container">
                    <h1 className="blogHeader">Our Blog</h1>
                    <BlogSection dataPosts={dataPosts} />
                </div>
            </div>
            {/* Blog end */}
        </>
    )
}