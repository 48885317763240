//ORGANIZATION
export const ORG_LIST = 'ORG_LIST'
export const ORG_ADD = 'ORG_ADD'
export const ORG_EDIT = 'ORG_EDIT'
export const ORG_DELETE = 'ORG_DELETE'

//ORG USER
export const ORG_USER_LIST = 'ORG_USER_LIST'
export const ORG_ADD_USER = 'ORG_ADD_USER'
export const ORG_EDIT_USER = 'ORG_EDIT_USER'
export const ORG_DELETE_USER = 'ORG_DELETE_USER'

//DOCTORS

export const ORG_DOCTOR_LIST = 'ORG_DOCTOR_LIST'
export const ORG_ADD_DOCTOR = 'ORG_ADD_DOCTOR'
export const ORG_EDIT_DOCTOR = 'ORG_EDIT_DOCTOR'
export const ORG_DELETE_DOCTOR = 'ORG_DELETE_DOCTOR'

//PATIENTS

export const ORG_PATIENT_LIST = 'ORG_PATIENT_LIST'
export const ORG_ADD_PATIENT = 'ORG_ADD_PATIENT'
export const ORG_EDIT_PATIENT = 'ORG_EDIT_PATIENT'
export const ORG_DELETE_PATIENT = 'ORG_DELETE_PATIENT'