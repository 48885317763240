import * as React from "react";
// import Paper from "@material-ui/core/Paper";
// import {
//   Chart,
//  // LineSeries,
//   Title,
//   //Legend,
// } from "@devexpress/dx-react-chart-material-ui";
// import { withStyles } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";
// import {
//   Animation, PieSeries,
// } from "@devexpress/dx-react-chart";
// import { curveCatmullRom, line } from "d3-shape";
// import { scalePoint } from "d3-scale";

// import { energyConsumption as data } from "./data-vizualization";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardIcon from "../../components/Card/CardIcon";
import Timeline from "@material-ui/icons/Timeline";
import ChartistGraph from "react-chartist";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import { colouredLinesChart } from "../../variables/charts";
import { makeStyles } from "@material-ui/core";
import styles from "../../assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { pieChart } from "../../variables/charts";
import CardFooter from "../../components/Card/CardFooter";

const useStyles = makeStyles(styles);
// const data2 = [
//   { sales: "Russia", area: 12 },
//   { sales: "Canada", area: 7 },
//   { sales: "India", area: 7 },
//   { sales: "Pakistan", area: 7 },
//   { sales: "China", area: 7 },
//   { sales: "Others", area: 60 },
// ];

const PatientDashboard = () => {
   const classes = useStyles();

  //  const ChartData1 = data2;
  // const chartData = data;
  // const { classes } = props;

  return (
    <div>
      <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Pie Chart</h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={pieChart.data}
                type="Pie"
                options={pieChart.options}
              />
            </CardBody>
            <CardFooter stats className={classes.cardFooter}>
              <h6 className={classes.legendTitle}>Legend</h6>
              <i className={"fas fa-circle " + classes.info} /> Apple{` `}
              <i className={"fas fa-circle " + classes.warning} /> Samsung
              {` `}
              <i className={"fas fa-circle " + classes.danger} /> Windows Phone
              {` `}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Pie Chart</h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={pieChart.data}
                type="Pie"
                options={pieChart.options}
              />
            </CardBody>
            <CardFooter stats className={classes.cardFooter}>
              <h6 className={classes.legendTitle}>Legend</h6>
              <i className={"fas fa-circle " + classes.info} /> Apple{` `}
              <i className={"fas fa-circle " + classes.warning} /> Samsung
              {` `}
              <i className={"fas fa-circle " + classes.danger} /> Windows Phone
              {` `}
            </CardFooter>
          </Card>
        </GridItem>
        {/* <GridItem sm={12} md={6} xs={12}>
          <Paper>
            <Chart data={ChartData1}>
              <PieSeries valueField="area" argumentField="user" />
              <Title text="User Score" />
              <Animation />
            </Chart>
          </Paper>
        </GridItem> */}
      </GridContainer>
      <div style={{ marginTop: "30px" }}>
        <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Coloured Lines Chart <small>- Rounded</small>
              </h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={colouredLinesChart.data}
                type="Line"
                options={colouredLinesChart.options}
                listener={colouredLinesChart.animation}
              />
            </CardBody>
          </Card>
        </GridItem> 
        </GridContainer>
      </div>
    </div>
  );
};

export default PatientDashboard;
