/* eslint-disable no-debugger */
import React from "react";
// import { useForm } from "react-hook-form";
// import { login } from "services/oauth";
import { useNavigate  } from "react-router-dom";
import { useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// import Icon from "@material-ui/core/Icon";
import { IoLogoFacebook, IoLogoAppleAppstore,IoLogoGoogle,IoLogoTwitter } from "react-icons/io5";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import axios from 'axios';
// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardFooter from "../../components/Card/CardFooter.js";
import Snackbar from "../../components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
// import { postUtil } from '../../services/axiosClient'
import styles from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { useForm } from "react-hook-form";
// import loginApiCall from './loginApi'
import CustomInput from "../../components/CustomInput/CustomInput";
import { Icon } from "@material-ui/core";
import firebase from "config/firebase-config.js";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const user = useSelector((state) => state.auth.user);
  const navigate  = useNavigate ();
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const { register, handleSubmit } = useForm({
    // defaultValues:{
    //   email: '',
    //   password: ''
    // }
  });
  const [error, setError] = React.useState(false);
  //handleSubmit(
  React.useEffect(() => {
    if (user) {
      // history.push("/admin/dashboard");
      navigate("/admin/dashboard");
    }
    let id = setTimeout(function () {
      setCardAnimation(""); 
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };


  });


  const classes = useStyles();

  const showNotification = (place) => {
    switch (place) {
      case "tr":
        if (!error) {
          setError(true);
          setTimeout(function () {
            setError(false);
          }, 6000);
        }
        break;
      default:
        break;
    }
  };
  const bgImage = '/Images/login.jpg';
  // const onSubmit = data => console.log(data);
/* eslint-disable no-debugger */
const loginApi = (data) =>{
  console.log("DATA", data)

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: {
      "UserName": data.email,
      "Password": data.password
    }
    // body: JSON.stringify({ UserName: data.email, Password: data.password, Token: "" })
  };
  // fetch('https://test.cosmaonline.com/Cosma/api/Account/login', requestOptions)
  axios.post('https://test.cosmaonline.com/Cosma/api/Account/login', {
    "UserName": data.email,
    "Password": data.password
  })
  .then(response => {
    if (response.status == 200) {
      localStorage.setItem("access_token", response.data.token.userToken);
      localStorage.setItem("userId", response.data.token.userId);
      localStorage.setItem("username", response.data.token.userName);
      navigate("/admin/dashboard");
    } else {
      showNotification("tr");
    }
  })


  // fetch('https://localhost:44345/api/Account/login', {
  //   method: 'POST',
  //   headers:{
  //     'Accept': 'application/json',
  //     'Content-Type': 'application/json',
  //   },
  //   body: {
  //     "UserName": "asasdfasdf",
  //     "Password": "123123"
  //   }
  // })
  //   .then(response => {
  //     console.log(JSON.stringify(response));
  //          debugger;
  //    if (response.status == 200) {
  //     (async () => {
  //         response = await response.json();
  //         console.log("RESPONSE", response)
  //         localStorage.setItem("access_token", response.userToken);
  //         localStorage.setItem("userId", response.userId);
  //         localStorage.setItem("username", response.userName);
  //         history.push("/admin/dashboard");
  //       })()
  //       } else {
  //       showNotification("tr");
  //     }
  //   })

  // const username = data.email.value;
  // const password = data.password.value;
  // debugger;
  // console.log("DATA", data);
  // const requestOptions = {
  //   method: "POST",
  //   headers: { "Content-Type": "application/json" },
  //   body: JSON.stringify({
  //     username: data.email,
  //     password: data.password,
  //     token: "",
  //   }),
  // };
  // fetch(
  //   "https://test.cosmaonline.com/cosma/api/Account/login",
  //   requestOptions
  // ).then((response) => {
  //   debugger
  //   // console.log(response, "response");
  //   // console.log(error, "error");
  //   if (response.status == 200) {
  //     response = response.json();
  //     localStorage.setItem("access_token", response.userToken);
  //     localStorage.setItem("userId", response.userId);
  //     localStorage.setItem("username", response.userName);

  //     history.push("/admin/dashboard");
  //   } else {
  //      showNotification("tr");
  //   }
  // });
}


function googleSignIn() {
  const googleAuthProvider = new GoogleAuthProvider();
  return signInWithPopup(firebase, googleAuthProvider)
  .then((res) =>{
    console.log(res);
    // history("/admin/dasboard");
    navigate("/admin/dashboard");
  })
  .catch((err)=>{
    console.log(err)
  })
}
function facebookSignIn() {
  const facebookAuthProvider = new FacebookAuthProvider();
  return signInWithPopup(firebase, facebookAuthProvider)
  .then((res) =>{
    console.log(res);
    // history.push("/admin/dashboard");
    navigate("/admin/dashboard");
  })
  .catch((err)=>{
    console.log(err)
  })
}
function twitterSignIn() {
  const twitterAuthProvider = new TwitterAuthProvider();
  return signInWithPopup(firebase, twitterAuthProvider)
  .then((res) =>{
    console.log(res);
    // history.push("/admin/dashboard");
    navigate("/admin/dashboard");
  })
  .catch((err)=>{
    console.log(err)
  })
}
function appleSignIn() {
  const appleAuthProvider = new OAuthProvider('apple.com');;
  return signInWithPopup(firebase, appleAuthProvider)
  .then((res) =>{
    console.log(res);
    // history.push("/admin/dashboard");
    navigate("/admin/dashboard");
  })
  .catch((err)=>{
    console.log(err)
  })
}


  return (
    <div id="loginWrap" style={{ backgroundImage: `url(${bgImage})` }}>
    <div className={classes.container} >
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleSubmit(loginApi)}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`} style={{ backgroundColor: `#46166B` }}
              >
                <h4 className={classes.cardTitle}>Log in</h4>
                <div className={classes.socialLine}>
                  {[
                    <IoLogoFacebook onClick={facebookSignIn} />,
                    <IoLogoGoogle onClick={googleSignIn} />,
                    <IoLogoTwitter onClick={twitterSignIn} />,
                    <IoLogoAppleAppstore onClick={appleSignIn}/>,
                  ].map((prop, key) => {
                    return (
                      <Button 
                        color="transparent"
                        justIcon
                        key={key}
                        className={classes.customButtonClass}
                      >
                        {prop}
                      </Button>
                    );
                  })}
                </div>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  name="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    ...register('email', { required: true }),
                    endadornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  // inputRef={register("password", { required: true })}
                  // inputRef={passwordRef}
                  {...register('email')}
                  labelText="Password"
                  id="password"
                  name="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    ...register("password", { required: true }),
                    endadornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button style={{ backgroundColor: `#46166B` }} type="submit" color="rose" simple size="lg" block>
                  Let{"'"}s Go
                </Button>
                <Snackbar
                  place="tr"
                  color="danger"
                  icon={AddAlert}
                  message="Failed to authenticate"
                  open={error}
                  closeNotification={() => setError(false)}
                  close
                />
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
    </div>
  );
}
