import React, { useEffect, useNavigate } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import GridItem from "../../../components/Grid/GridItem.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import Card from "../../../components/Card/Card.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import CardBody from "../../../components/Card/CardBody.js";
import { Button } from "@material-ui/core";
import CreateModal from "./CreateModal";
import { EditRounded, RotateRightRounded, Cancel } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchFamilys } from "../../../actions/UploadedUI/FamilyAction";
import Swal from "sweetalert2";
import { deleteFamily } from "../../../actions/UploadedUI/FamilyAction";

const styles = {
  garageTitle: {
    display: "inline-block",
    float: "right",
  },
  rightText: {
    display: "inline-block",
    margin: "10px",
  },
  garageRowTitle: {
    fontSize: "22px",
    fontWeight: "bold",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      textAlign: "right",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }
// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];
// // const handleEdit = (values) => {
//   console.log("The Values that you wish to edit ", values);
// };

export default function FamilyUI() {
  const classes = useStyles();

  const families=useSelector(state=>state.fam.families);
  const dispatch=useDispatch();
  const history = useNavigate()
   useEffect(()=>{
       dispatch(fetchFamilys(families))
   },[]);

  const handleDelete = (id) => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          deleteFamily(id);
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
            )
          history.push("admin/FamilyUI");
          dispatch(fetchFamilys(families));
        }
      })
    }
  const handleEdit = (values) => {
    console.log("The Values that you wish to edit ", values);
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          {/* <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>FamilyUI <h4><b>Family</b></h4> </Icon>
              </CardIcon>
            </CardHeader> */}
          <CardHeader color="primary">
            <div className={classes.rightText}>
              <h4 className={classes.rightText}>Family UI</h4>
              <Button className={classes.garageTitle} alignRight>
                <CreateModal />{" "}
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>First Name</TableCell>
                    <TableCell align="center">Last Name</TableCell>
                    <TableCell align="center">RelationShip</TableCell>
                    {/* <TableCell align="center">City</TableCell>
                    <TableCell align="center">Country</TableCell> */}
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {families.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="center">{row.firstName}</TableCell>
                      <TableCell align="center">{row.lastName}</TableCell>
                      <TableCell align="center">{row.relationship}</TableCell>
                      <TableCell align="right">
                        <EditRounded
                         color="primary"
                          onClick={() => handleEdit(row.id)}
                        />
                        <RotateRightRounded 
                        style={{ color: 'green' }}
                        />
                        <Cancel 
                         style={{ color: 'red' }}
                         onClick={()=>handleDelete(row.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
