import React, { useEffect } from "react";
import  oauth, { loadUserFromStorage } from "./pages/dashboardNew/services/oauth";
import Home from './pages/home';
import Layout from './Components/Layout';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/dashboardNew/views/UserProfile/LoginPage';
import SignUp from './pages/signup';
import Feature from './pages/features';
import Subscribe from './pages/subscribe';
import Blog from './pages/blog';
import HealthCarePro from './pages/healthcare-professionals';
import ResearchOrg from './pages/research-organisations';
import HealthCareOrg from './pages/healthcare-organisations';
import DementiaCom from './pages/dementia-communities';
import TermsOfUse from './pages/terms-of-use';
import CheckOut from './pages/checkout';
import store from './pages/dashboardNew/store';
import { Provider } from "react-redux";
import PrivateRoute from "./pages/dashboardNew/utils/protectedRoute";
import AuthProvider from "./pages/dashboardNew/utils/authProvider";
import Dashboard from './pages/dashboardNew/layouts/Admin';
import RTL from "./pages/dashboardNew/layouts/RTL.js";
import SinglePost from './pages/singlePage';
import Bookdemo from "pages/bookdemo";


function App() {
    useEffect(() => {
    // fetch current user
    loadUserFromStorage(store);
  }, []);
  return (

    <Router>
      <Layout>
     <Provider store={store}>
        <AuthProvider userManager={oauth} store={store}>
        <Routes>
          <React.Fragment>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/features" element={<Feature />} />
            <Route exact path="/subscribe" element={<Subscribe />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog/:id" element={<SinglePost />} />
            <Route exact path="/healthcare-professionals" element={<HealthCarePro />} />
            <Route exact path="/research-organisations" element={<ResearchOrg />} />
            <Route exact path="/healthcare-organisations" element={<HealthCareOrg />} />
            <Route exact path="/dementia-communities" element={<DementiaCom />} />
            <Route exact path="/terms-of-service" element={<TermsOfUse />} />
            <Route exact path="/checkout" element={<CheckOut />} />
            <Route exact path="/bookdemo" element={<Bookdemo />} />

            <Route path='/dashboard' element={<PrivateRoute/>}> 
              <Route path="/dashboard" element={<Dashboard />} /> 
            </Route> 
            <Route path='/rtl' element={<PrivateRoute/>}> 
              <Route path="/rtl" element={<RTL />} />
            </Route>
            
          </React.Fragment>
        </Routes>
        </AuthProvider>
        </Provider> 
      </Layout>
    </Router>

  );
}

export default App;
