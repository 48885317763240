import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDWEffmrZaAFCf1FNBw_NbsfCbHcXvpCmc",
  authDomain: "cosma-4526b.firebaseapp.com",
  projectId: "cosma-4526b",
  storageBucket: "cosma-4526b.appspot.com",
  messagingSenderId: "1016796826393",
  appId: "1:1016796826393:web:6722d84126e2ca36149388",
  measurementId: "G-NSFNPGW2HY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firebase = getAuth(app);

export default firebase;

