import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
//import DialogContentText from '@material-ui/core/DialogContentText';
import CardHeader from "../../../components/Card/CardHeader.js";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";

const styles = {
  rightText: {
    display: "inline-block",
    float: "right",
    color: "#FFFFFF",
  },
  textMargin: {
    marginTop: "10px",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function CreateModal() {
  const [open, SetOpen] = useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    SetOpen(true);
  };

  const handleClose = () => {
    SetOpen(false);
  };

  return (
    <div>
      <Button
        className={classes.rightText}
        variant="outlined"
        onClick={handleClickOpen}
      >
        Add New
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="Add-Behavioural"
        className={classes.cardTitleWhite}
      >
        <CardHeader color="primary">
          <DialogTitle id="Add-Behavioural" className={classes.textMargin}>
            {" "}
            Add Behavioural
          </DialogTitle>
        </CardHeader>
        <DialogContent xs={8} sm={8} md={8}>
          <TextField
            autoFocus
            required
            margin="dense"
            id="firstName"
            label="First Name"
            type="firstName"
            variant="outlined"
            fullWidth
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="lastName"
            label="Last Name"
            type="lastName"
            fullWidth
            variant="outlined"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="relationship"
            label="Realtionship"
            type="relationship"
            fullWidth
            variant="outlined"
          />
          <TextField
            required
            autoFocus
            multiline
            rows={4}
            margin="dense"
            id="description"
            label="Description"
            type="description"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            Save
          </Button>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
