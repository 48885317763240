
import * as UploadedAction from '../../Types/UploadedUITypes';
import axios from 'axios';

const baseUrl =  "https://test.cosmaonline.com/cosma/api";
const token = localStorage.getItem("access_token");

export const fetchFamilys=()=>{
    return function(dispatch){
        axios.get(baseUrl + '/cosmaresource/family', {
                headers: {
                    'Authorization': 'bearer ' + token,
                    'Content-Type': 'application/json'
                }
            }
        )
        .then(res=>
            {
                const families = res.data;
                dispatch({
                  type:UploadedAction.FAMILY_LIST,
                  payload:families
                 });
            })
        .catch(err=>console.log(err));  
    }
}

export const GETFamilyById = (Id) => {
  return function (dispatch) {
    axios
      .get(baseUrl + "/FamilyUi/GetFamilyById?Id=" + Id, {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        const family = response.data;
 
        dispatch({
          type: UploadedAction.FAMILY_EDIT,
          payload: family,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const CREATEFamily = (family) => {
  axios
    .post(baseUrl + "​​/FamilyUi/SaveFamily", family, {
      headers: {
        Authorization: "bearer " + token,
        "content-type": "application/json",
      },
    })
    .then(function () {
      return {
        type: UploadedAction.FAMILY_ADD,
        payload: family,
      };
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const deleteFamily = (id) => {
  axios
    .post(baseUrl + "/FamilyUi/DeleteEvent?Id=" + id, {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
    })
    .then((res) => console.log(res))
    .catch((err) => console.log(err));

  return {
    type: UploadedAction.FAMILY_DELETE,
    payload: id,
  };
};
