import * as UploadedAction from '../../Types/UploadedUITypes';

const initialState={
    behavioral:'',
    behaviourals:[],
    id:'',
}

const ScheduleReducer=(state=initialState, action)=>{

    switch(action.type){
        case UploadedAction.BEHAVIOURAL_LIST:return{
            ...state,
            behaviourals:action.payload
        }
        case UploadedAction.BEHAVIOURAL_ADD:return{
            ...state,
            behavioral:action.payload
        }
        case UploadedAction.BEHAVIOURAL_EDIT:return{
            ...state,
            behavioral:action.payload,          
        }
        case UploadedAction.BEHAVIOURAL_DELETE:return{
            ...state
        }
        default:return state;
    }
}

export default ScheduleReducer;