import * as OrganizationAction from '../../Types/organizationTypes';

const initialState={
    organization:'',
    allOrganization:[],
    id:'',
}

const organizationReducer=(state=initialState, action)=>{

    switch(action.type){
        // case ADD_PASSCAT:return{
        //     ...state,
        //     category:action.payload
        // }
        case OrganizationAction.ORG_LIST:return{
            ...state,
            allOrganization:action.payload
        }
        case OrganizationAction.ORG_ADD:return{
            ...state,
            organization:action.payload
        }
        case OrganizationAction.ORG_EDIT:return{
            ...state,
            organization:action.payload,          
        }
        case OrganizationAction.ORG_DELETE:return{
            ...state
        }
        default:return state;
    }
}

export default organizationReducer;