import Loading from 'Components/ContentLoading';
import Iframe from 'react-iframe';

export default function Newsletter (props) {

    const {heading, shortDes} = props;
    const data = props.homeAllData;
    if (!data) return <Loading/>;
    const {subscribe_img, subscribe_background_color} = data[0].subscribe_image;
    
   
    return(
        <section id='newsletter' className="py-9 subscribeWwrap" style={{background: `${subscribe_background_color}` }}>
                <div class="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div>
                                <img src={subscribe_img.url} alt="ww" />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="contenWraper mt-4 text-center">
                                <h3 className="subsTitle">{heading}</h3>
                                <p className="parte">{shortDes}</p>
                                <div>
                                    {/* <form >
                                        <div className="row">
                                            <div className="col-lg-8 col-md-8 col-sm-8 col-8">
                                                <input className="me-3 subscribeInput" placeholder="Enter your Name" type="text" />
                                                <input className="subscribeInput" placeholder="Enter your Email" type="text" />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-4 ps-0">
                                                <button type="submit" className="Subscribe">Subscribe</button>
                                            </div>
                                        </div>
                                    </form> */}
                                    <Iframe 
                                    url="https://cosmatherapy.co.uk/newsletter"
                                    width="100%"
                                    height="300px"
                                    id="myId"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}